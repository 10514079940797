import "../globalStyles/scss/index.scss";
import "./scss/index.scss";

import React from "react";
import { RouteComponentProps } from "react-router";

import { hotjar } from "react-hotjar";

import { MetaConsumer, OverlayManager } from "../components";
import { Routes } from "./routes";
import ThemeProvider from "./ThemeProvider";

import CovidHeader from "src/components/CovidHeader";
import MainHeader from "src/components/MainHeader";
import MainFooter from "src/components/MainFooter";

import { HOTJAR_ID, HOTJAR_SNIPPET_VERSION, OUR_ENV } from "../constants";
import { MainHeaderProvider } from "@temp/app/MainHeaderProvider";
import * as paths from "./routes/paths";

if (OUR_ENV === "production") {
  hotjar.initialize(Number(HOTJAR_ID), Number(HOTJAR_SNIPPET_VERSION));
}

const App: React.FC<RouteComponentProps> = ({
  history: {
    location: { pathname },
  },
}) => {
  return (
    <div className="app" style={{ minHeight: document.documentElement.clientHeight }}>
      <ThemeProvider>
        <MainHeaderProvider>
          <MetaConsumer />
          {/*{pathname === paths.baseUrl && <CovidHeader />}*/}
          <header className="header-block">
            {pathname + "/" !== paths.signUpFormUrl && pathname !== paths.signUpFormUrl && (
              <MainHeader />
            )}
          </header>
          <Routes />
          {pathname + "/" !== paths.signUpFormUrl && pathname !== paths.signUpFormUrl && (
            <>
              <div className="pad" />
              <MainFooter />
            </>
          )}
          <OverlayManager />
        </MainHeaderProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
