import React from "react";
import "./scss/index.scss";

import ReactSVG from "react-svg";
import mapIconPath from "src/images/map-icon.svg";

const Country = ({
  countryCode,
  countries,
  setCountryCode,
  setIsCountryVisible,
  countryDivRef,
}) => {
  React.useEffect(() => {
    const handleMouseDown = (event) => {
      if (!countryDivRef.current.contains(event.target)) {
        setIsCountryVisible(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <div className="location">
      {countries.map((item) => (
        <div
          className="location-item"
          key={item.code}
          onClick={() => {
            setCountryCode(item.code);
            setIsCountryVisible(false);
          }}
          onMouseDown={(event) => event.preventDefault()}
        >
          <ReactSVG path={mapIconPath} />
          <span
            style={{
              fontWeight: countryCode === item.code ? "bold" : null,
            }}
          >
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Country;
