import * as React from "react";
import SEOQuery from "@temp/components/SEO/SEOQuery";

interface SEOWrapperProps {
  pathname: string;
  image?: string;
  children: any;
}

const SEOWrapper = ({ pathname, image, children }: SEOWrapperProps) => {
  pathname = pathname.split("?")[0];

  if (image === null || image === undefined) {
    image = `${window.location.origin}/images/poster.jpg`;
  }
  return (
    <SEOQuery slug={pathname} pathname={pathname} image={image}>
      {children}
    </SEOQuery>
  );
};

export default SEOWrapper;
