import React from "react";

import { withApollo } from "react-apollo";
import {
  getPasses as getPassesRequest,
  getSeller as getSellerRequest,
} from "./hotelDetailsRequests";
import { getImages } from "./ImagesCarouselRequests";

import { Loader } from "src/components";
import HotelDetailsView from "./HotelDetailsView";
import { storeDate } from "@temp/utils";

const HotelDetailsQuery = ({ client, hotelSlug, date, updateHeader }) => {
  const [loading, setLoading] = React.useState(true);
  const [seller, setSeller] = React.useState(null);
  const [venuePasses, setVenuePasses] = React.useState(null);
  const [imagesSources, setImagesSources] = React.useState(null);

  const [, setSelectedDate] = React.useState(new Date());

  const setDateWithLocalStorage = (date) => {
    setSelectedDate(date);
    storeDate(date, true);
  };

  React.useEffect(() => {
    (async () => {
      const getSeller = await getSellerRequest(client, { slug: hotelSlug, day: date });

      const {
        data: { seller },
        loading,
      } = getSeller;
      setLoading(loading);
      setSeller(seller);

      {
        setLoading(true);
        const {
          data: { hotelImages },
          loading,
        } = await getImages(client, { sellerInfoId: seller.sellerInfo.id });
        setLoading(loading);
        setImagesSources(hotelImages);
      }

      if (seller?.user?.id) {
        setLoading(true);
        const {
          data: { sellerPasses },
          loading,
        } = await getPassesRequest(client, {
          sellerId: seller.id,
          day: date,
        });

        updateHeader({ onlyLogo: true, customLogo: seller?.user?.avatar?.url });

        setLoading(loading);
        setVenuePasses(sellerPasses);
      }
    })();
  }, [date]);

  return loading ? (
    <Loader />
  ) : seller && venuePasses ? (
    <HotelDetailsView
      seller={seller}
      venuePasses={venuePasses}
      hotelSlug={hotelSlug}
      selectedDate={date}
      setSelectedDate={setDateWithLocalStorage}
      imagesSources={imagesSources}
    />
  ) : null;
};

export default withApollo(HotelDetailsQuery);
