import React from "react";
import PdfViewer from "@temp/components/PdfViewer";
import { TypedSiteQuery } from "@temp/components/Onboarding/queries";

const Onboarding = () => {
  return (
    <TypedSiteQuery>
      {({ data }) => {
        return <PdfViewer url={data.site.onboardingPdf} />;
      }}
    </TypedSiteQuery>
  );
};

export default Onboarding;
