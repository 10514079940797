import gql from "graphql-tag";

export const userFragment = gql`
  fragment User on User {
    id
    email
    firstName
    lastName
    isStaff
    freshdeskId
    defaultShippingAddress {
      ...Address
    }
    defaultBillingAddress {
      ...Address
    }
    addresses {
      ...Address
    }
  }
`;
