import { createBrowserHistory } from "history";
import * as gtag from "./lib/gtag.js";
import * as fbq from "./lib/fbq.js";

const history = createBrowserHistory();
history.listen((_location, action) => {
  gtag.pageview(_location.pathname);
  fbq.pageview();

  if (["PUSH"].includes(action)) {
    window.scroll({
      behavior: "smooth",
      top: 0,
    });
  }
});

export { history };
