import "./scss/index.scss";

import * as React from "react";
import { FormattedMessage } from "react-intl";
import { SEOWrapper } from "@temp/components/SEO";

const QuestionSection = ({ sectionTitle, sectionText }) => {
  return (
    <>
      <h2 className="western">
        <span style={{ fontSize: "x-large", fontWeight: "bold" }}>
          <strong>{sectionTitle}</strong>
        </span>
      </h2>
      <p>{sectionText}</p>
    </>
  );
};

class Covid extends React.Component<{}> {
  render() {
    const covidTexts = [
      {
        question: <FormattedMessage id={"covid.question1"} />,
        answer: <FormattedMessage id={"covid.answer1"} />,
      },
      {
        question: <FormattedMessage id={"covid.question2"} />,
        answer: <FormattedMessage id={"covid.answer2"} />,
      },
      {
        question: <FormattedMessage id={"covid.question3"} />,
        answer: (
          <FormattedMessage
            id={"covid.answer3"}
            values={{ breakingLine: <br /> }}
          />
        ),
      },
      {
        question: <FormattedMessage id={"covid.question4"} />,
        answer: (
          <FormattedMessage
            id={"covid.answer4"}
            values={{
              breakingLine: <br />,
              WHOLink: (
                <a
                  href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public"
                  target="_blank"
                  style={{ color: "blue" }}
                >
                  <FormattedMessage id="worldHealthOrganization" />
                </a>
              ),
              SHAThaiLink: (
                <a
                  href="https://thailandsha.tourismthailand.org/"
                  target="_blank"
                  style={{ color: "blue" }}
                >
                  <FormattedMessage id="shaThai" />
                </a>
              ),
              ThaiMFALink: (
                <a
                  href="http://www.mfa.go.th/main/en/customize/115227-COVID-19.html"
                  target="_blank"
                >
                  <FormattedMessage id="ThaiMFA" />
                </a>
              ),
            }}
          />
        ),
      },
    ];
    return (
      <SEOWrapper pathname={window.location.pathname}>
        <div className="covid">
          <br />
          <br />
          <p>
            <span style={{ fontSize: "x-large" }}>
              <strong>
                <FormattedMessage id="covid.header" />
              </strong>
            </span>
          </p>
          <p>&nbsp;</p>
          <FormattedMessage
            id="covid.welcomeText"
            values={{ breakingLine: <br /> }}
          />
          {covidTexts.map(
            (texts, index) =>
              texts?.question &&
              texts?.answer && (
                <QuestionSection
                  key={`questionSection${index}`}
                  sectionTitle={texts.question}
                  sectionText={texts.answer}
                />
              )
          )}
          <p>&nbsp;</p>
        </div>
      </SEOWrapper>
    );
  }
}

export default Covid;
