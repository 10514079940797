import "./scss/index.scss";

import * as React from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../core/config";
import Button from "../Button";
import { FormattedMessage } from "react-intl";
import * as gtag from "../../lib/gtag.js";
import { SEOWrapper } from "@temp/components/SEO";

interface NotFoundProps {
  message?: string;
}

const NotFound: React.FC<NotFoundProps> = () => {
  gtag.pageview(`${window.location.href}#404`);

  return (
    <SEOWrapper pathname={window.location.pathname}>
      <div className="not-found-page">
        <h2 className="not-found-page__header">404</h2>
        <div className="not-found-page__ruler" />
        <div className="not-found-page__message">
          <p>
            <FormattedMessage id="text.page_not_found" />
          </p>
          <p>
            <FormattedMessage id="text.mistyped_or_moved" />
          </p>
          <p>
            <FormattedMessage id="text.page_not_found" />
          </p>
        </div>
        <div className="not-found-page__button">
          <Link to={BASE_URL}>
            <Button secondary>
              <FormattedMessage id="text.backtohome" />
            </Button>
          </Link>
        </div>
      </div>
    </SEOWrapper>
  );
};

export default NotFound;
