import React from "react";
import { FlexDirectionProperty, FlexWrapProperty, JustifyContentProperty } from "csstype";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type PassTypeIconProps = {
  direction?: FlexDirectionProperty;
  flexWrap?: FlexWrapProperty;
  justifyContent?: JustifyContentProperty;
  iconMargin?: number | string;
  iconSize?: number | string;
  passTypeLinks: string[];
};

type StyleProps = {
  direction: FlexDirectionProperty;
  flexWrap: FlexWrapProperty;
  justifyContent: JustifyContentProperty;
  iconMargin: number | string;
  iconSize: number | string;
};

const PassTypeIcon: React.FC<PassTypeIconProps> = ({
  direction = "row",
  flexWrap = "nowrap",
  justifyContent = "flex-start",
  iconMargin = 5,
  iconSize = 35,
  passTypeLinks,
}) => {
  const classes = useStyles({ direction, flexWrap, justifyContent, iconMargin, iconSize });

  return (
    <div className={classes.container}>
      {passTypeLinks?.map((link, index) => {
        return <img alt={`type-icon`} className={classes.icon} key={index} src={link} />;
      })}
    </div>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    display: "flex",
    flexDirection: ({ direction }) => direction,
    flexWrap: ({ flexWrap }) => flexWrap,
    justifyContent: ({ justifyContent }) => justifyContent,
  },
  icon: {
    height: ({ iconSize }) => iconSize,
    width: ({ iconSize }) => iconSize,
    margin: ({ iconMargin }) => iconMargin,
  },
}));

export default PassTypeIcon;
