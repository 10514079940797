import gql from "graphql-tag";

const GET_IMAGES = gql`
  query ($sellerInfoId: ID!) {
    hotelImages(sellerInfoId: $sellerInfoId) {
      id
      url
      shortDescription
    }
  }
`;

export async function getImages(client, { sellerInfoId }) {
  const { data, loading } = await client.query({
    query: GET_IMAGES,
    variables: { sellerInfoId },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}
