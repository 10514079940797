import React from "react";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";

import * as paths from "src/app/routes/paths";
import { rbrColors } from "src/app/ThemeProvider";

const BookNowLocalButton = ({ dis, bookNowHandler, classes }) => {
  if (!dis) {
    return (
      <Button
        classes={{
          root: classes.bookNowButton,
          label: classes.buttonLabel,
        }}
        onClick={bookNowHandler}
      >
        BOOK NOW
      </Button>
    );
  } else {
    return (
      <Button
        classes={{
          root: classes.bookNowButton,
          label: classes.buttonLabel,
        }}
        disabled
      >
        BOOK NOW
      </Button>
    );
  }
};

const BookNowButton = (props: BookNowButtonProps) => {
  const classes = useStyles();
  const [showAlertMessage, setShowAlertMessage] = React.useState(false);

  const bookNowHandler = () => {
    if (props.available) {
      props.history.push(paths.checkoutLoginUrl);
    } else {
      setShowAlertMessage(true);
    }
  };

  return (
    <>
      <div
        className={classes.bookNowButtonContainer}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        <BookNowLocalButton
          bookNowHandler={bookNowHandler}
          classes={classes}
          dis={showAlertMessage}
        />
      </div>

      {showAlertMessage && (
        <Alert className={classes.alertMessage} severity="error">
          The hotel does not accept any bookings, send a request for this offer to the hotel{" "}
          <a
            className={classes.link}
            href={`mailto:${props.reservationEmail}?subject=Request for ${
              props.passType
            } offer&body=Hi,%0D%0A%0D%0AI want to make a booking for a ${
              props.passType
            } Pass from your page ${encodeURIComponent(
              window.location.href
            )} ,%0D%0A but your hotel does not accept any bookings. When is the offer gonna be available?%0D%0A%0D%0APlease contact me back via this email%0D%0A%0D%0AThank you!`}
          >
            here
          </a>
        </Alert>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  buttonLabel: {
    color: "#ffffff !important",
  },
  bookNowButton: {
    backgroundColor: rbrColors.turquoise,
    color: "#ffffff !important",
    fontWeight: 500,
    fontSize: "1.25rem",
    margin: "1rem",
    marginBottom: "1.5rem",
    borderRadius: "50px",
    width: "10.5rem",
    "&:hover": {
      backgroundColor: darken(rbrColors.turquoise, 0.1),
    },
  },
  bookNowButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  alertMessage: {
    marginBottom: "1rem",
  },
  link: {
    color: "blue",
  },
}));

// types are incorrect here. Correct them if possible
interface BookNowButtonProps extends RouteComponentProps {
  available: any;
  reservationEmail: string;
  passType: string;
}

export default withRouter(BookNowButton);
