import gql from "graphql-tag";

const GET_SELLERS = gql`
  query SellerList($filter: SellerUserFilterInput, $first: Int, $after: String, $day: Date!) {
    sellerUsers(filter: $filter, first: $first, after: $after, day: $day) {
      edges {
        cursor
        node {
          id
          slug
          acceptingBookings
          passTypes {
            id
            name
            icon
          }
          sellerInfo {
            id
            hotelName
            hotelDescription
            hotelAddress
            starRating
            hotelShortDescription
            area {
              name
            }
            mainHotelImage
          }
          user {
            id
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export async function getSellers(client, { filter, first, after, day }) {
  const { data, loading } = await client.query({
    query: GET_SELLERS,
    variables: { filter, first, after, day },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}
