import * as React from "react";

import { Button } from "@material-ui/core";
import { Form, TextField } from "../../../components";
import { IGuestAddressProps } from "../../types";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import "./scss/index.scss";

const GuestAddressForm: React.FC<IGuestAddressProps> = ({ onSubmit }) => {
  const intl = useIntl();
  const formattedMessageIdPrefix = "src.checkout.components.GuestAddressForm.GuestAddressForm.";
  const [sendEmailNews, setSendEmailNews] = React.useState<boolean>(true);
  const data = { sendEmailNews, firstName: "", lastName: "", email: "", phone: "" };

  return (
    <div className="address-form">
      <Form
        onSubmit={(evt, data) => {
          evt.preventDefault();
          onSubmit(data);
        }}
        data={data}
      >
        <fieldset>
          <div className="address-form__grid">
            <TextField
              label={intl.formatMessage({
                id: `${formattedMessageIdPrefix}firstName`,
              })}
              type="given-name"
              name="firstName"
              autoComplete="given-name"
              required
            />
            <TextField
              label={intl.formatMessage({
                id: `${formattedMessageIdPrefix}lastName`,
              })}
              type="family-name"
              name="lastName"
              autoComplete="family-name"
              required
            />
          </div>
          <div
            className={classNames("address-form__grid", {
              "address-form__grid--full": true,
            })}
          >
            <TextField
              label={intl.formatMessage({
                id: `${formattedMessageIdPrefix}email`,
              })}
              type="email"
              autoComplete="email"
              name="email"
              required
            />

            <TextField
              label={intl.formatMessage({
                id: `${formattedMessageIdPrefix}phoneNumber`,
              })}
              type="tel"
              name="phone"
              autoComplete="phone"
              required
            />
            <div className="address-form__copy-address">
              <div className="custom-checkbox">
                <label className="container">
                  <input
                    name="sendEmailNews"
                    type="checkbox"
                    // value={sendEmailNews ? "true" : ""}
                    checked={sendEmailNews}
                    onClick={(event) => {
                      const target = event.target as HTMLInputElement;
                      setSendEmailNews(target.value !== "true");
                    }}
                  />
                  <span>
                    <FormattedMessage id="text.yesreceivemail" />
                  </span>
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </div>
        </fieldset>
        {/*ToDo: add this and similar from HotelListVew to the common component Button if possible when whole site will be redesigned*/}
        <div className="regularButtonContainer">
          <Button type="submit" className="regularButton" variant="contained" color="primary">
            {"Pay at the hotel"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default GuestAddressForm;
