import React from "react";

import moment from "moment";

import { withApollo, WithApolloClient } from "react-apollo";

import { Loader } from "src/components";

import HotelsListView from "./HotelsListView";

import { getSellers } from "./hotelsListRequests";

interface HotelsListQueryProps {
  params: { location: string; date: string; country: string }; // check types
}

const HotelsListQuery: React.FC<WithApolloClient<HotelsListQueryProps>> = ({
  client,
  params: { location, date, country },
}) => {
  const [loading, setLoading] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [sellers, setSellers] = React.useState({
    sellerUsers: { edges: [], pageInfo: null },
  });
  const formatedDate = moment(date).format("YYYY-MM-DD");

  const queryFilterSettings = {
    date: formatedDate,
    // locationName: location,
    countryKey: country,
  };
  const hotelsQuerySize = 15;

  React.useEffect(() => {
    (async () => {
      const getSellersResult = await getSellers(client, {
        filter: queryFilterSettings,
        first: hotelsQuerySize,
        day: formatedDate,
      });

      const { data, loading } = getSellersResult;
      setSellers(data);
      setLoading(loading);
    })();
  }, []);

  return loading ? (
    <Loader />
  ) : sellers.sellerUsers?.edges ? (
    <HotelsListView
      data={sellers}
      locationName={location}
      countryCode={country}
      date={date}
      loadingMore={loadingMore}
      onLoadMore={() => {
        setLoadingMore(true);
        (async () => {
          const nextSellers = await getSellers(client, {
            filter: queryFilterSettings,
            first: hotelsQuerySize,
            after: sellers.sellerUsers.pageInfo.endCursor,
            day: formatedDate,
          });

          const { data: nextSellersData, loadingNextSellers } = nextSellers;
          sellers.sellerUsers.edges.push(...nextSellersData.sellerUsers.edges);
          sellers.sellerUsers.pageInfo.endCursor = nextSellersData.sellerUsers.pageInfo.endCursor;
          sellers.sellerUsers.pageInfo.hasNextPage =
            nextSellersData.sellerUsers.pageInfo.hasNextPage;
          setLoadingMore(loadingNextSellers);
        })();
      }}
    />
  ) : null;
};

export default withApollo(HotelsListQuery);
