import * as React from "react";
import { generatePath, Redirect, RouteComponentProps } from "react-router";

import * as paths from ".";

export const CheckoutRouteDispatcher: React.FC<
  RouteComponentProps<{
    token?: string;
  }>
> = ({
  match: {
    params: { token },
  },
}) => {
  // case CheckoutStep.BillingAddress:
  return <Redirect to={generatePath(paths.billingUrl, { token })} />;
  // case CheckoutStep.Review:
  //   return <Redirect to={generatePath(paths.reviewUrl, { token })} />;
};
