import React from "react";
import "../scss/index.scss";

import dates from "./dates";

import CalendarGrid from "./CalendarGrid";

const Calendar = ({
  setDate,
  setIsCalendarVisible,
  calendarDivRef,
  defaultMonthIndex,
  timeForStorageInputs,
}) => {
  React.useEffect(() => {
    const handleMouseDown = (event) => {
      if (!calendarDivRef.current.contains(event.target)) {
        setIsCalendarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const firstMonth = dates[0];
  const [month, setMonth] = React.useState(firstMonth);
  // if value comes from localStorage and it's appropriate
  // then set month for this value

  React.useEffect(() => {
    if (defaultMonthIndex !== null) {
      setMonth(dates[defaultMonthIndex]);
    } else {
      const isFirstMonthCurrent = dates[0].find((item) => {
        return item.find((item) => item.defaultValue === true);
      });
      const firstMonth = isFirstMonthCurrent ? dates[0] : dates[1];
      setMonth(firstMonth);
    }
  }, []);

  const [monthIndex, setMonthIndex] = React.useState(0);

  React.useEffect(() => {
    setMonthIndex(
      dates.findIndex((item) => {
        return item === month;
      })
    );
  }, [month]);

  return (
    <div className="calendar" onMouseDown={(event) => event.preventDefault()}>
      <CalendarGrid
        month={month}
        monthIndex={monthIndex}
        setMonth={(index) => setMonth(dates[index])}
        setDate={setDate}
        timeForStorageInputs={timeForStorageInputs}
        setIsCalendarVisible={setIsCalendarVisible}
      />
    </div>
  );
};

export default Calendar;
