import * as React from "react";

export const purchase = (value) => {
  window.fbq("track", "Purchase", value);
};

export const pageview = () => {
  window.fbq("track", "PageView");
};

export const viewContent = ( value ) => {
  window.fbq('track', 'ViewContent', value);
}

export const searched = ( value ) => {
  window.fbq('track', 'Search', value);
}

/* FB InitiateCheckout

        fbq('track', 'InitiateCheckout', {
      // Fire the 'InitiateCheckout' event on the search results page

      // IF YOU CHOOSE NOT TO USE A RECOMMENDED PARAM, THEN REMOVE IT, DON'T LEAVE IT EMPTY

      // REQUIRED: DO NOT change this, must be set to 'hotel'
      content_type: 'hotel',

      // HIGHLY RECOMMENDED: checkin date
      // Allows you to target people based on their travel dates (using a booking window)
      // Improves the landing experience with travel dates filled in (using template tags)
      // use YYYYMMDD, YYYY-MM-DD, YYYY-MM-DDThh:mmTZD or YYYY-MM-DDThh:mm:ssTZD
      checkin_date: '2018-04-01',

      // HIGHLY RECOMMENDED: checkout date
      // use YYYYMMDD, YYYY-MM-DD, YYYY-MM-DDThh:mmTZD or YYYY-MM-DDThh:mm:ssTZD
      checkout_date: '2018-04-05',

      // REQUIRED: content ids - include eg top 5 search results
      content_ids: '123',

      // REQUIRED: city, don't use abbreviations
      city: 'New York',

      // REQUIRED: region, don't use abbreviations
      region: 'New York',

      // REQUIRED: country, don't use abbreviations
      country: 'United States',

      // RECOMMENDED: number of adults
      num_adults: 1,

      // RECOMMENDED: number of children
      num_children: 0,


    }

     */
export const initiateCheckout = ( value ) => {
  window.fbq('track', 'InitiateCheckout', value);
}

