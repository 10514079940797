import { gaId } from "../constants";
// Source: https://stackoverflow.com/questions/51071478/how-to-add-gtag-js-to-react-js-next-js-index-js
export const GA_TRACKING_ID = gaId;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, value) => {
  window.gtag("event", action, value);
};
