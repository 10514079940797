import "../scss/index.scss";

import React from "react";

const Line = ({
  title,
  value = "",
  extraClasses = "",
  extraTitleClasses = "",
}) => {
  return (
    <div className="line" key={title}>
      <div className={"line-container " + extraClasses}>
        <span className={extraTitleClasses}>{title}</span>
        <span>{value}</span>
      </div>
    </div>
  );
};

export default Line;
