import React from "react";
import "./scss/index.scss";

/*
import Media from "react-media";

import { Link } from "react-router-dom";
import ReactSVG from "react-svg";

import {
  MenuDropdown,
  Online,
  OverlayContext,
  OverlayTheme,
  OverlayType,
} from "..";
import * as appPaths from "../../app/routes";

import { CheckoutContext } from "../../checkout/context";
import { CartContext } from "../CartProvider/context";
*/

const MainMenu: React.FC = () => {
  /*
  const { data: user } = useUserDetails();
  const [signOut] = useSignOut();
  const { clear: clearCart } = useContext(CartContext);
  const { clear: clearCheckout } = useContext(CheckoutContext);

  const handleSignOut = () => {
    clearCart();
    clearCheckout();
    signOut();
  };
*/
  return null;
  // return (
  //   <OverlayContext.Consumer>
  //     {overlayContext => (
  //       <Online>
  //         <Media
  //           query={{ minWidth: 0 }}
  //           render={() => (
  //             <>
  //               {user ? (
  //                 <MenuDropdown
  //                   head={
  //                     <button className="auth-button">
  //                       Account
  //                     </button>
  //                   }
  //                   content={
  //                     <ul className="main-menu__dropdown">
  //                       <li data-testid="my_account__link">
  //                         <Link to={appPaths.accountUrl}>My Account</Link>
  //                       </li>
  //                       <li
  //                         onClick={handleSignOut}
  //                         data-testid="logout-link"
  //                       >
  //                         Log Out
  //                       </li>
  //                     </ul>
  //                   }
  //                 />
  //               ) : (
  //                   <span
  //                     data-testid="login-btn"
  //                     onClick={() =>
  //                       overlayContext.show(
  //                         OverlayType.login,
  //                         OverlayTheme.right
  //                       )
  //                     }
  //                   >
  //                     <button className="auth-button">
  //                       Log In | Register
  //                     </button>
  //                   </span>
  //                 )}
  //             </>
  //           )}
  //         />
  //       </Online>
  //     )}
  //   </OverlayContext.Consumer>
  // );
};

export default MainMenu;
