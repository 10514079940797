import gql from "graphql-tag";

const GET_SEO = gql`
  query SEO($slug: String!) {
    page(slug: $slug) {
      contentJson
      id
      seoDescription
      seoTitle
      slug
      title
      keywords {
        keyword
      }
    }
  }
`;

export async function getSEO(client, { slug }) {
  const { data, loading } = await client.query({
    query: GET_SEO,
    variables: { slug },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}
