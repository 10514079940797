import "./scss/index.scss";

import * as React from "react";

import { SocialMediaIcon } from "..";
import { SOCIAL_MEDIA } from "../../core/config";
import Nav from "./Nav";

const Footer: React.FC = () => (
  <>
    <div className="footer__pad" />
    <div className="footer" id="footer">
      <div className="footer__favicons container">
        {SOCIAL_MEDIA.map((medium) => (
          <SocialMediaIcon medium={medium} key={medium.ariaLabel} />
        ))}
      </div>
      <Nav />
    </div>
  </>
);

export default Footer;
