import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";

const CREATE_SELLER = gql`
  mutation ($input: SellerRegisterInput!) {
    sellerRegister(input: $input) {
      errors {
        field
        message
      }
      accountErrors {
        code
        field
        message
      }
      user {
        id
      }
    }
  }
`;

export async function registerSeller(client, input) {
  const {
    data: { sellerRegister },
  } = await client.mutate({
    mutation: CREATE_SELLER,
    variables: { input },
  });

  return sellerRegister;
}

export const GET_USER = gql`
  query ($user: ID!) {
    seller(user: $user) {
      id
      sellerInfo {
        id
      }
    }
  }
`;

export async function getUser(client, user) {
  const { data } = await client.query({
    query: GET_USER,
    variables: { user },
  });

  return {
    sellerInfoId: data.seller.sellerInfo.id,
    sellerId: data.seller.id,
  };
}

export const CREATE_STRIPE_CHECKOUT = gql`
  mutation ($successUrl: String!, $sellerId: ID!) {
    createStripeCheckout(successUrl: $successUrl, sellerId: $sellerId) {
      checkoutUrl
    }
  }
`;

export async function createStripeCheckout(client, successUrl, sellerId) {
  const { data } = await client.mutate({
    mutation: CREATE_STRIPE_CHECKOUT,
    variables: { successUrl, sellerId },
  });
  return {
    checkoutUrl: data.createStripeCheckout.checkoutUrl,
  };
}

export const SEND_UPSELL_INFO = gql`
  mutation ($input: UpsellInput!) {
    sendUpsellInfo(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;

export async function sendUpsellInfo(client, input) {
  const { data } = await client.mutate({
    mutation: SEND_UPSELL_INFO,
    variables: { input },
  });
  return data;
}

const getSite = gql`
  query Site {
    site {
      explanationVideo
    }
  }
`;

export const TypedSiteQuery = TypedQuery(getSite);
