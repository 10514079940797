import * as React from "react";
import { RouteComponentProps } from "react-router";
import Page from "./Page";

const View: React.FC<RouteComponentProps<{ token?: string }>> = ({
  history,
  match: {
    params: { token },
  },
}) => {
  const checkoutInfo = JSON.parse(localStorage.getItem("checkoutInfo"));
  // const { sendEmailNews, setSendEmailNews } = fullCartContext;

  // const voucherData = cartLines[0];
  // const mainImageUrl = voucherData?.mainHotelImage?.[0]?.url;
  // const passType = voucherData.type;
  // const voucherTitle = `${passType} Pass`;
  return (
    <Page
      checkoutInfo={checkoutInfo}
      history={history}
      // sendEmailNews={sendEmailNews}
      // setSendEmailNews={setSendEmailNews}
      // mainImageUrl={mainImageUrl}
      // voucherData={voucherData}
      // voucherTitle={voucherTitle}
    />
  );
};

export default View;
