import React from "react";
import { IProps } from "./types";

export const Money: React.FC<IProps> = ({ money, defaultValue }: IProps) => {
  if (!money) {
    return <span>{defaultValue}</span>;
  }
  return <span>&#3647;{money.amount}</span>;
};

Money.displayName = "Money";
export default Money;
