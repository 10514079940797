import gql from "graphql-tag";
import moment from "moment";

const GET_SELLER = gql`
  query ($slug: String!, $day: Date) {
    seller(slug: $slug, day: $day) {
      id
      slug
      notificationEmail
      hotelWebsite
      contactEmail
      contactPhone
      contactPerson
      contactPersonTitle
      reservationEmail
      reservationPhone
      acceptingBookings
      shaCertified
      passTypes {
        id
        name
        icon
        venues {
          id
          description
          openingHour
          closingHour
          secondarySectionName
          secondaryOpeningHour
          secondaryClosingHour
          menu
          passes {
            id
            description
          }
        }
      }
      sellerInfo {
        id
        hotelName
        hotelDescription
        hotelShortDescription
        status
        starRating
        hotelAddress
        latitude
        longitude
        area {
          id
          name
          location {
            id
            name
          }
        }
        mainHotelImage
        generalTermsTitle
        generalTermsText
        mapsEmbeddedLink
      }
      user {
        id
        email
        isActive
        avatar {
          url
        }
      }
    }
  }
`;

export async function getSeller(client, { slug, day }) {
  const { data, loading } = await client.query({
    query: GET_SELLER,
    variables: { slug, day: moment(day).format("YYYY-MM-DD") },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}

const GET_SELLER_PASSES = gql`
  query ($sellerId: ID!, $day: Date!) {
    sellerPasses(sellerId: $sellerId, day: $day) {
      id
      isForSale
      isBestSeller
      sellingPrice
      currency
      childPrice
      allowChildren
      passTypeName
      passTypeIconLink
      description
      seatsLeft
    }
  }
`;

export async function getPasses(client, { sellerId, day }) {
  const { data, loading } = await client.query({
    query: GET_SELLER_PASSES,
    variables: { sellerId, day: moment(day).format("YYYY-MM-DD") },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}
