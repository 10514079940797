import moment from "moment-timezone";
import { currencySymbols } from "@temp/constants";

const baseDateFormat = "D MMMM Y";
export const timezone = "Asia/Bangkok";
const STORAGE_PICK_DATE_KEY = "pickDate";
const STORAGE_PICK_MOMENT_KEY = "pickDateMoment";

export function getDefaultDate() {
  return moment.tz(timezone).add(1, "days").format(baseDateFormat);
}

export const getCurrencySymbol = (currencyCode) => {
  if (currencyCode && currencySymbols[currencyCode]) {
    return currencySymbols[currencyCode];
  }
  return currencyCode;
};

export function makeCorrectDateUrl(history) {
  const searchParams = new URLSearchParams(history.location.search);
  const dateParamName = "date";
  let date = searchParams.get(dateParamName);
  if (!date) {
    date = getDefaultDate();
  } else {
    const oldDate = date;
    date = makeDateValidated(date);
    if (date !== oldDate) {
      searchParams.set(dateParamName, date);
      const search = ("?" + searchParams.toString()).replace(
        RegExp("([?&]" + dateParamName + "(?=[=&#]|$)[^#&]*|(?=#|$))"),
        dateParamName + "=" + encodeURIComponent(date).replace(/^([^?&]+)&/, "$1?")
      );
      history.replace({ search: "?" + search });
    }
  }
  storeDate(date);
  return date;
}

export function makeDateValidated(date) {
  date = moment.tz(date, timezone).startOf("day");
  if (date < moment.tz(timezone).startOf("day")) {
    return getDefaultDate();
  }
  return date.format(baseDateFormat);
}

export function storeDate(date, saveMoment = false) {
  localStorage.setItem(STORAGE_PICK_DATE_KEY, date);
  localStorage.removeItem(STORAGE_PICK_MOMENT_KEY);
  if (saveMoment) {
    const pickDateMoment = Number(new Date()).toString();
    localStorage.setItem(STORAGE_PICK_MOMENT_KEY, pickDateMoment);
  }
}
