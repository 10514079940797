import moment from "moment";
import { timezone } from "@temp/utils";

enum WeekDays {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
}

const rowMonthCreator = (monthDates) => {
  const dates = monthDates.slice();

  const firstRow = createFirstRow(dates);
  const lastRow = createLastRow(dates);

  const rows = [firstRow, ...createMiddleRows(dates), lastRow];

  return rows;
};

const createFirstRow = (dates) => {
  const first = dates[0];

  switch (first.day) {
    case WeekDays.SUNDAY:
      return fillRow(dates);
    case WeekDays.MONDAY:
      return [...createEmptyCells(1), ...fillRow(dates)];
    case WeekDays.TUESDAY:
      return [...createEmptyCells(2), ...fillRow(dates)];
    case WeekDays.WEDNESDAY:
      return [...createEmptyCells(3), ...fillRow(dates)];
    case WeekDays.THURSDAY:
      return [...createEmptyCells(4), ...fillRow(dates)];
    case WeekDays.FRIDAY:
      return [...createEmptyCells(5), ...fillRow(dates)];
    case WeekDays.SATURDAY:
      return [...createEmptyCells(6), ...fillRow(dates)];
    default: // do nothing
  }
};

const createLastRow = (dates) => {
  const last = dates[dates.length - 1];

  switch (last.day) {
    case WeekDays.SUNDAY:
      return [...fillRowBackward(dates), ...createEmptyCells(6)];
    case WeekDays.MONDAY:
      return [...fillRowBackward(dates), ...createEmptyCells(5)];
    case WeekDays.TUESDAY:
      return [...fillRowBackward(dates), ...createEmptyCells(4)];
    case WeekDays.WEDNESDAY:
      return [...fillRowBackward(dates), ...createEmptyCells(3)];
    case WeekDays.THURSDAY:
      return [...fillRowBackward(dates), ...createEmptyCells(2)];
    case WeekDays.FRIDAY:
      return [...fillRowBackward(dates), ...createEmptyCells(1)];
    case WeekDays.SATURDAY:
      return fillRowBackward(dates);
    default: // do nothing
  }
};

const createMiddleRows = (dates) => {
  const rows = [];

  const extractDays = () => {
    if (dates.length === 0) {
      return;
    }

    rows.push(dates.splice(0, 7));

    extractDays();
  };

  extractDays();

  return rows;
};

const fillRow = (dates) => {
  const row = [];

  const extractDays = () => {
    const date = dates.shift();

    if (date.day === WeekDays.SATURDAY) {
      row.push(date);
      return;
    }

    row.push(date);

    extractDays();
  };

  extractDays();

  return row;
};

const fillRowBackward = (dates) => {
  const row = [];

  const extractDays = () => {
    const date = dates.pop();

    if (date.day === WeekDays.SUNDAY) {
      row.unshift(date);
      return;
    }

    row.unshift(date);

    extractDays();
  };

  extractDays();

  return row;
};

const createEmptyCells = (numberEmptyCells) => {
  const emptyCells = Array.from({ length: numberEmptyCells }, () => ({
    day: null,
  }));

  return emptyCells;
};

const generateDays = (month) => {
  const days = [];
  const firstMonthDay = moment.tz(timezone).date(1).add(month, "M");
  const daysInMonth = firstMonthDay.daysInMonth();
  const monthIndex = firstMonthDay.month();
  const year = firstMonthDay.year();

  for (let i = 1; i <= daysInMonth; i++) {
    const date = moment.tz(new Date(year, monthIndex, i), timezone);
    const day = date.format("dddd");
    const isActive =
      moment.tz(timezone).isBefore(date, "day") || moment.tz(timezone).isSame(date, "day");

    const defaultDate =
      moment.tz(timezone).format("dddd") === "Saturday"
        ? moment.tz(timezone).add(1, "days")
        : moment.tz(timezone).day("Saturday");

    const defaultValue = defaultDate.isSame(date, "day");
    days.push({ date, day, isActive, defaultValue });
  }
  return rowMonthCreator(days);
};

const months = [];

for (let i = 0; i < 12; i++) {
  months.push(generateDays(i));
}

export default months;
