import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage } from "react-intl";

import "./scss/index.scss";
import calendarPath from "src/images/calendar-group.svg";
import poolPath from "src/images/how-it-works_qr-check-in.svg";
import searchPath from "src/images/search-group.svg";

const DrinksBookStyle = {
  textAlign: "center" as "center",
  marginBottom: "18px",
};

const leftAndRightStyle = {
  marginBottom: "15px",
};

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <div id="howitworks" className="how-it-works-title">
        <FormattedMessage id="header.howitworks" />
      </div>
      <div className="directions">
        <div>
          <ReactSVG className="how-it-works-svg" path={searchPath} />
          <div style={leftAndRightStyle}>
            <FormattedMessage id="onboarding.findhotel" />
          </div>
          <div>
            <FormattedMessage id="onboarding.findhoteldetail" />
          </div>
        </div>
        <div>
          <ReactSVG path={calendarPath} />
          <div style={DrinksBookStyle}>
            <FormattedMessage id="onboarding.choosedate" />
          </div>
          <div>
            <FormattedMessage id="onboarding.choosedatedetail" />
          </div>
        </div>
        <div>
          <ReactSVG path={poolPath} />
          <div style={leftAndRightStyle}>
            <FormattedMessage id="onboarding.enjoydaycation" />
          </div>
          <div>
            <FormattedMessage id="onboarding.enjoydaycationdetail" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
