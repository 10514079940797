import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Rating } from "@material-ui/lab";

import Avatar from "@material-ui/core/Avatar";

const HotelInfoReviewsView = ({ reviewsData }) => {
  const classes = useStyles();
  const totalReviews = reviewsData.totalCount;

  return (
    <div className={classes.container}>
      <div className={classes.totalReviews}>Reviews ({totalReviews})</div>
      {reviewsData.edges.map((review, index) => {
        const {
          node: { firstName, lastName, created: reviewDate, text, starRating },
        } = review;
        const avatarLetter = firstName[0];
        const guestName = `${firstName} ${lastName}`;

        return (
          <div key={index} className={classes.review}>
            <div className={classes.requisites}>
              <Avatar className={classes.avatar}>{avatarLetter}</Avatar>
              <div className={classes.nameAndDate}>
                <div className={classes.name}>{guestName}</div>
                <div className={classes.date}>
                  {reviewDate.substr(0, reviewDate.indexOf("T"))}
                </div>
              </div>
            </div>
            {/*<div className={classes.title}>{title}</div>*/}
            <Rating name="rating" readOnly size="small" value={starRating} />
            <div className={classes.text}>{text}</div>
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    alignSelf: "center",
    background: theme.customColors.primaryTransparent,
    color: theme.palette.primary.contrastText,
    height: "2.2rem",
    marginRight: "1rem",
    width: "2.2rem",
  },
  container: {
    width: "100%",
  },
  date: {
    color: theme.palette.text.disabled,
    fontSize: "0.75rem",
    fontWeight: 400,
  },
  name: {
    fontSize: "0.75rem",
    marginBottom: "5px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  nameAndDate: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
  },
  requisites: {
    display: "flex",
    marginBottom: "5px",
  },
  review: {
    borderBottom: `1px solid ${theme.customColors.grid}`,
    display: "flex",
    flexDirection: "column",
    padding: "1.3rem 0",
  },
  text: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    marginTop: "1rem",
    color: theme.palette.text.primary,
    wordBreak: "break-word",
  },
  title: {
    fontSize: "0.9rem",
    fontWeight: 500,
    margin: "1rem 0 0.3rem",
    color: theme.palette.text.primary,
  },
  totalReviews: {
    borderBottom: `1px solid ${theme.customColors.grid}`,
    fontSize: "1.15rem",
    color: theme.palette.text.primary,
    fontWeight: 500,
    paddingBottom: "0.3rem",
  },
}));

export default HotelInfoReviewsView;
