import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import HotelsListQuery from "./HotelsListQuery";
import { SEOWrapper } from "@temp/components/SEO";
import { makeCorrectDateUrl } from "@temp/utils";

const HotelsList = (props: MyComponentProps) => {
  const date = makeCorrectDateUrl(props.history);
  const searchParams = new URLSearchParams(props.history.location.search);
  const countryParamName = "country";
  const country = searchParams.get(countryParamName);

  const params = { date, location: props.match.params.location, country };

  const location = window.location;

  return (
    <SEOWrapper pathname={location.pathname}>
      <HotelsListQuery params={params} />
    </SEOWrapper>
  );
};
type TParams = { location: string };

interface MyComponentProps extends RouteComponentProps<TParams> {
  date?: string;
}

export default withRouter(HotelsList);
