import "./scss/index.scss";

import * as React from "react";
import { SEOWrapper } from "@temp/components/SEO";
import { FormattedMessage } from "react-intl";
import { TypedSiteQuery } from "./queries";

const QuestionSection = ({ sectionTitle, sectionText }) => {
  return (
    <>
      <h2 className="western">
        <span style={{ fontSize: "x-large", fontWeight: "bold" }}>
          <strong>{sectionTitle}</strong>
        </span>
      </h2>
      {sectionText.split("\\n").map((str) => (
        <p>{str}</p>
      ))}
    </>
  );
};

class FAQ extends React.Component<{}> {
  render() {
    return (
      <SEOWrapper pathname={window.location.pathname}>
        <TypedSiteQuery>
          {({ data }) => {
            return (
              <div className="faq">
                <br />
                <br />

                <p>
                  <span style={{ fontSize: "x-large" }}>
                    <strong>
                      <FormattedMessage id="faq.header" />
                    </strong>
                  </span>
                </p>
                <h2 className="western">
                  <span style={{ fontSize: "x-large", fontWeight: "bold" }}>
                    <FormattedMessage id="faq.subheader" />
                  </span>
                </h2>
                <p>&nbsp;</p>
                {data?.site?.faq.map((texts, index) => (
                  <QuestionSection
                    key={`questionSection${index}`}
                    sectionTitle={texts[0]}
                    sectionText={texts[1]}
                  />
                ))}
                <p>&nbsp;</p>
              </div>
            );
          }}
        </TypedSiteQuery>
      </SEOWrapper>
    );
  }
}

export default FAQ;
