import React from "react";
import "./scss/index.scss";

import { HashLink as Link } from "react-router-hash-link";
import * as paths from "src/app/routes/paths";
import { dashboardUrl } from "../../constants";

import ReactSVG from "react-svg";
import facebookPath from "src/images/facebook-icon.svg";
import twitterPath from "src/images/twitter-icon.svg";
import instagramPath from "src/images/instagram-icon.svg";
import { FormattedMessage } from "react-intl";
import { useLocalStorage } from "@hooks";
import { defaultLocale, messages } from "@temp/components/Locale/Locale";
import ImagesCarousel from "@temp/components/HotelDetails/ImagesCarousel";
import { partnerHotelImages, StyleProps } from "@temp/components/SignUpForm/SignUpForm";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { signUpFormUrl } from "src/app/routes/paths";
// import customerAcquisitionImagePath from "src/images/sign-up/customer-acquisition.png";
const formStyle = {
  marginRight: "-3.5vw",
};

export interface MainFooterProps {
  hideMap?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  hotelsRoot: {
    width: "35vw",

    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },

    "& > div": {
      height: "auto",
      alignItems: "center",
      "& .alice-carousel__wrapper": {
        height: "10vh",
        "& li": {
          height: "100%",
          "& img": {
            height: "100%",
            width: "100%",
            objectFit: "contain",
            opacity: "0.3",
          },
        },
      },
      "& > div": {
        margin: "5px",
        "& > div": {
          backgroundColor: "unset",
        },
      },
    },
  },
}));

const MainFooter: React.FC<MainFooterProps> = ({ hideMap }) => {
  const { storedValue: locale } = useLocalStorage("locale", defaultLocale);
  const classes = useStyles();
  const [email, setEmail] = React.useState("");

  const onSignUpButtonPress = (event) => {
    const validateEmail = (email) => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    if (!validateEmail(email)) {
      event.preventDefault();
      window.alert(messages[locale]["text.entervalidemail"]);
    }
  };

  return (
    <div className="footer">
      {!hideMap && (
        <div className="map" id="footer-map">
          <div className="map-left">
            <div>
              <span>
                <FormattedMessage id="footer.discover" />
              </span>
              <div>
                <Link to={paths.baseUrl + "#howitworks"}>
                  <span>
                    <FormattedMessage id="footer.howitworks" />
                  </span>
                </Link>
                {/*<span>Browse Hotels</span>*/}
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="footer.partners" />
              </span>
              <div>
                <Link to={paths.signUpFormUrl}>
                  <span>
                    <FormattedMessage id="footer.partner_sign_up" />
                  </span>
                </Link>
                <a href={dashboardUrl}>
                  <span>
                    <FormattedMessage id="footer.hotelpartnerlogin" />
                  </span>
                </a>
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="footer.support" />
              </span>
              <div>
                <Link to={paths.faq}>
                  <span>
                    <FormattedMessage id="footer.faq" />
                  </span>
                </Link>
                {/*<a href="mailto:hello@dailypass.com?subject=DailyPass%20Contact%20Request%20HP">*/}
                {/*  <span>*/}
                {/*    <FormattedMessage id="footer.contactus" />*/}
                {/*  </span>*/}
                {/*</a>*/}
                <Link to={paths.privacy}>
                  <span>
                    <FormattedMessage id="footer.privacypolicy" />
                  </span>
                </Link>
                <Link to={paths.termsandconditions}>
                  <span>
                    <FormattedMessage id="footer.termsconditions" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="map-right">
            <span>
              <FormattedMessage id="footer.subscribe" />
            </span>
            <div>
              <span>
                <FormattedMessage id="footer.signup" />
                {/*<br />*/}
                {/*<FormattedMessage id="footer.signup2" />*/}
              </span>
              <div className={classes.hotelsRoot}>
                <ImagesCarousel imagesSources={partnerHotelImages} hotelName={"kek"} />
              </div>
            </div>
            <div>
              {/*<form*/}
              {/*  action="https://DailyPass.us8.list-manage.com/subscribe/post?u=d551067f8e2c9224101526d8d&amp;id=598b5ef5ca"*/}
              {/*  method="post"*/}
              {/*  id="mc-embedded-subscribe-form"*/}
              {/*  name="mc-embedded-subscribe-form"*/}
              {/*  target="_blank"*/}
              {/*  noValidate*/}
              {/*  style={formStyle}*/}
              {/*>*/}
              {/*  <input*/}
              {/*    type="email"*/}
              {/*    name="EMAIL"*/}
              {/*    id="mce-EMAIL"*/}
              {/*    placeholder={messages[locale]["emailcta.enteremail"]}*/}
              {/*    onChange={(event) => setEmail(event.target.value)}*/}
              {/*    value={email}*/}
              {/*    required*/}
              {/*  />*/}
              {/*  <button*/}
              {/*    className="sign-up-button"*/}
              {/*    type="submit"*/}
              {/*    defaultValue="Subscribe"*/}
              {/*    name="subscribe"*/}
              {/*    id="mc-embedded-subscribe"*/}
              {/*    onClick={onSignUpButtonPress}*/}
              {/*  >*/}
              {/*    <FormattedMessage id="header.signup" />*/}
              {/*  </button>*/}
              <button
                className="sign-up-button"
                type="submit"
                defaultValue="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                style={{ width: "fit-content", padding: "5px" }}
                onClick={() => (window.location.href = signUpFormUrl)}
              >
                Become a partner
              </button>
              {/*</form>*/}
            </div>
          </div>
        </div>
      )}
      <div className="socials" id="footer-socials">
        <span>&copy; {new Date().getFullYear()} Mashub Pte. Ltd.</span>
        <div>
          <a href={paths.facebookUrl} target="_blank">
            <ReactSVG path={facebookPath} />
          </a>
          <a href={paths.twitterUrl} target="_blank">
            <ReactSVG path={twitterPath} />
          </a>
          <a href={paths.instagramUrl} target="_blank">
            <ReactSVG path={instagramPath} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
