import "./scss/index.scss";

import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import { Button } from "../../components";
import { BASE_URL } from "../../core/config";
// import { checkoutLoginUrl } from "../../app/routes/paths";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { defaultLocale, messages } from "@temp/components/Locale/Locale";
import { useLocalStorage } from "@hooks";
import { SEOWrapper } from "@temp/components/SEO";

const View: React.FC<RouteComponentProps> = ({ history }) => {
  const { storedValue: locale } = useLocalStorage("locale", defaultLocale);
  const alert = useAlert();

  const error = window.localStorage.lastPaymentError;

  if (error) {
    window.localStorage.removeItem("lastPaymentError");
    alert.error(
      {
        content: error,
        title: messages[locale]["text.error"],
      },
      {
        timeout: 30000,
      }
    );
  }

  return (
    <SEOWrapper pathname={window.location.pathname}>
      <div className="order-confirmation">
        <h3>
          <FormattedMessage id="text.order.unsuccessful1" /> <br />
          <FormattedMessage id="text.order.unsuccessful2" />
        </h3>
        <p className="order-confirmation__info">
          <FormattedMessage id="text.contactus" />
        </p>
        <div className="order-confirmation__actions">
          <Link to={BASE_URL}>
            <Button secondary>
              <FormattedMessage id="text.homepage" />
            </Button>
          </Link>
          {/* <Link to={checkoutLoginUrl}>
                    <Button secondary>Back To Order</Button>
                </Link> */}
        </div>
      </div>
    </SEOWrapper>
  );
};

export default View;
