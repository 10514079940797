import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import AliceCarousel from "react-alice-carousel";
import "./alice-carousel.scss";
import "./custom-react-bnb-carousel.scss";

import ReactSVG from "react-svg";
import moveLeftPath from "src/images/move-left.svg";
import moveRightPath from "src/images/move-right.svg";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import { useIntl } from "react-intl";

const ImagesCarousel = ({ imagesSources, hotelName }) => {
  const classes = useStyles();
  const intl = useIntl();

  const responsive = {
    0: {
      items: 1,
    },
    500: {
      items: 2,
    },
    1023: {
      items: 3,
    },
  };

  const noPhotosProvided = intl.formatMessage({
    id: "bnb-gallery.noPhotosProvided",
  });
  const showPhotoList = intl.formatMessage({ id: "bnb-gallery.showPhotoList" });
  const hidePhotoList = intl.formatMessage({ id: "bnb-gallery.hidePhotoList" });

  const bnbGalleryPhrases = {
    noPhotosProvided,
    showPhotoList,
    hidePhotoList,
  };

  const [, setImagesQuantityOnScreen] = React.useState(imagesQuantity(responsive));
  const [showFullscreenCarousel, setShowFullscreenCarousel] = React.useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = React.useState(0);

  const movePrevRef = React.useRef(null);
  const moveNextRef = React.useRef(null);

  const carouselRef = React.useRef(null);

  const handleOnDragStart = (event) => event.preventDefault();

  const onSlideChanged = ({ isPrevSlideDisabled, isNextSlideDisabled }) => {
    /*
        isPrevSlideDisabled ?
          movePrevRef.current.container.style.visibility = "hidden" :
          movePrevRef.current.container.style.visibility = "visible";

        isNextSlideDisabled ?
          moveNextRef.current.container.style.visibility = "hidden" :
          moveNextRef.current.container.style.visibility = "visible";

     */
  };
  type imgSrcType = { url: string; shortDescription: string };
  const onResized = (event) => {
    setImagesQuantityOnScreen(event.itemsInSlide);
  };

  return (
    <div className={classes.carouselContainer} onMouseDown={(event) => event.preventDefault()}>
      <div className="alice-carousel-move">
        <ReactSVG
          className={classes.move}
          style={{ visibility: "visible" }}
          ref={movePrevRef}
          path={moveLeftPath}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() =>
            carouselRef && carouselRef.current ? carouselRef.current.slidePrev() : null
          }
        />
      </div>
      <AliceCarousel
        ref={carouselRef}
        mouseTrackingEnabled
        responsive={responsive}
        buttonsDisabled={true}
        infinite={false}
        autoHeight={false}
        dotsDisabled
        fadeOutAnimation
        onSlideChanged={onSlideChanged}
        onResized={onResized}
      >
        {imagesSources.map((imgSrc, index) => (
          <img
            key={imgSrc.id}
            src={imgSrc.url}
            onDragStart={handleOnDragStart}
            onClick={() => {
              setActivePhotoIndex(index);
              setShowFullscreenCarousel(true);
              document.body.style.overflow = "hidden";
              document.getElementById("fc_frame").style.visibility = "hidden";
            }}
          />
        ))}
      </AliceCarousel>
      <div className="alice-carousel-move">
        <ReactSVG
          style={{ visibility: "visible" }}
          className={classes.move}
          ref={moveNextRef}
          path={moveRightPath}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() =>
            carouselRef && carouselRef.current ? carouselRef.current.slideNext() : null
          }
        />
      </div>
      <ReactBnbGallery
        show={showFullscreenCarousel}
        activePhotoIndex={activePhotoIndex}
        photos={Array.from(imagesSources, (imgSrc: imgSrcType) => {
          return {
            photo: imgSrc.url,
            caption: hotelName,
            subcaption: imgSrc.shortDescription,
          };
        })}
        onClose={() => {
          setShowFullscreenCarousel(false);
          document.body.style.overflow = "auto";
          document.getElementById("fc_frame").style.visibility = "visible";
        }}
        phrases={bnbGalleryPhrases}
      />
    </div>
  );
};

const imagesQuantity = (responsive) => {
  const screenWidth = document.body.getBoundingClientRect().width;
  const responsiveKeys = Object.keys(responsive);

  for (const key of responsiveKeys.reverse()) {
    if (screenWidth > +key) {
      return responsive[key].items;
    }
  }
};

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: "flex",
    height: "300px",
    [theme.breakpoints.down("xs")]: {
      height: "280px",
    },
  },
  hotelImage: {
    width: "100%",
  },
  move: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.13)",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& path": {
        fill: "#fff",
      },
    },
    "& svg": {
      height: "2rem",
      width: "auto",
      cursor: "pointer",
      "& path": {
        fill: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        height: "2.8rem",
      },
    },
  },
}));

export default ImagesCarousel;
