import * as React from "react";
import MetaWrapper from "@temp/components/Meta/MetaWrapper";

const SEOView = ({
  description,
  title,
  keywords,
  children,
  pathname,
  image,
  noindex,
}) => {
  return (
    <MetaWrapper
      meta={{
        description: description,
        title: title,
        keywords: keywords,
        noindex: noindex,
        url: pathname,
        image: image,
      }}
    >
      {children}
    </MetaWrapper>
  );
};

export default SEOView;
