import React, { useEffect } from "react";
import "./scss/index.scss";
import { Button } from "@material-ui/core";

const BecomePartnerButton = ({ email }) => {
  const handleButtonClick = (e) => {
    window.location.href = `mailto:${email}`;
    e.preventDefault();
  };

  useEffect(() => {
    const signUpButton = document.querySelector("#becomePartnerButtonWrapper");
    const footerSocials = document.querySelector("#footer-socials");
    const footerMap = document.querySelector("#footer-map");
    const paddingBottom = window
      .getComputedStyle(footerMap, null)
      .getPropertyValue("padding-bottom");

    signUpButton.style.marginTop = `calc(100vh - ${signUpButton.clientHeight}px - 10px)`;

    function checkOffset() {
      const visibleFooterSocials = Math.max(
        0,
        document.documentElement.clientHeight - footerSocials.getBoundingClientRect().y
      );
      signUpButton.style.marginTop = `calc(100vh - ${visibleFooterSocials}px - ${signUpButton.clientHeight}px - 10px)`;
      if (footerMap) {
        const computedStyle = window.getComputedStyle(footerMap, null);
        const paddingTop = computedStyle.getPropertyValue("padding-top");
        const paddingRight = computedStyle.getPropertyValue("padding-right");
        const paddingLeft = computedStyle.getPropertyValue("padding-left");
        const visibleButton = signUpButton.firstChild;
        footerMap.style.padding = `${paddingTop} ${paddingRight} calc(${paddingBottom} + ${visibleButton.offsetHeight}px + 20px) ${paddingLeft}`;
      }
      const facebookMessanger = document.querySelector(
        "#fb-root .fb_dialog_content iframe:first-child"
      );
      let facebookMessangerWidth = 0;
      if (facebookMessanger) {
        const width = facebookMessanger.style.width.replace(/\D/g, "");
        const margin = facebookMessanger.style.marginLeft.replace(/\D/g, "");
        facebookMessangerWidth = Number(width) + Number(margin);
      }
      signUpButton.style.right = `calc(20px + ${facebookMessangerWidth}px)`;
    }

    document.addEventListener("scroll", () => checkOffset());
  }, []);

  return (
    <div className="becomePartnerButtonWrapper" id="becomePartnerButtonWrapper">
      <Button variant="contained" color="primary" size="large" onClick={handleButtonClick}>
        Contact Us
      </Button>
    </div>
  );
};

export default BecomePartnerButton;
