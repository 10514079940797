import gql from "graphql-tag";

import { TypedQuery } from "../../core/queries";

export const GET_PASS_TYPES = gql`
  query PassTypes {
    passTypes {
      id
      showOnLanding
      landingName
      landingDescription
      landingImage
    }
  }
`;

export const TypedPassTypesQuery = TypedQuery(GET_PASS_TYPES);
