import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { RouteComponentProps, withRouter } from "react-router-dom";
import * as paths from "src/app/routes/paths";
import { confirmationUrl, dashboardUrl } from "../../constants";
import { HashLink } from "react-router-hash-link";
import InputAdornment from "@material-ui/core/InputAdornment";

import { withApollo, WithApolloClient } from "react-apollo";
import { getUser, registerSeller, sendUpsellInfo, TypedSiteQuery } from "./signUpFormRequests";
import ReactSVG from "react-svg";
import logoPath from "src/images/logo-daily-pass-light.svg";
import { Button, TextField, Theme } from "@material-ui/core";
import { useAlert } from "react-alert";
import { isMobile } from "react-device-detect";
import Link from "@material-ui/core/Link";
import { allFieldsFilledValidation } from "./validations";
import { SEOWrapper } from "@temp/components/SEO";
import * as appPaths from "@temp/app/routes";
import whiteLabelImagePath from "src/images/sign-up/white-label.png";
import retailsQRImagePath from "src/images/sign-up/retails-qr-code.png";
import centralSystemImagePath from "src/images/sign-up/central-system.png";
// import customerAcquisitionImagePath from "src/images/sign-up/customer-acquisition.png";
import tickOrangeImagePath from "src/images/sign-up/tick_orange.png";
import hotelImagePath1 from "src/images/sign-up/hotels/InterContinental-Hotels-Group-Logo.svg.png";
import hotelImagePath2 from "src/images/sign-up/hotels/Hyatt_Logo.svg.png";
import hotelImagePath3 from "src/images/sign-up/hotels/Accor_Logo_2020.svg";
import hotelImagePath4 from "src/images/sign-up/hotels/Marriott_International_Logo.svg";
import hotelImagePath5 from "src/images/sign-up/hotels/Sheraton-Logo-1937.svg";
import hotelImagePath6 from "src/images/sign-up/hotels/Novotel_logo_(2016).svg.png";
import ImagesCarousel from "@temp/components/HotelDetails/ImagesCarousel";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import videoThumbnail from "images/sign-up/video_thumbnail.png";

function scrollToHash(hash) {
  const element = document.getElementById(hash);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}

export const partnerHotelImages = [
  {
    id: hotelImagePath3,
    url: hotelImagePath3,
  },
  {
    id: hotelImagePath4,
    url: hotelImagePath4,
  },
  {
    id: hotelImagePath2,
    url: hotelImagePath2,
  },
  {
    id: hotelImagePath1,
    url: hotelImagePath1,
  },
  {
    id: hotelImagePath5,
    url: hotelImagePath5,
  },
  {
    id: hotelImagePath6,
    url: hotelImagePath6,
  },
];
const ITEM_HEIGHT = 48;
const contactEmail = "hello@dailypass.com";
const options = [`Email: ${contactEmail}`];

type SignUpFormPropsInternal = WithApolloClient<RouteComponentProps>;

export interface StyleProps {
  mirror: boolean;
}

const InfoSection = ({
  sectionName,
  sectionLinkName,
  title,
  title2Line = "",
  paragraphs,
  buttonText,
  buttonSectionLinkName,
  imageLink,
  mirror = false,
}) => {
  const classes = useStyles({ mirror });
  return (
    <div id={sectionLinkName} className={classes.section}>
      <div className={classes.infoContainer}>
        <p className={classes.sectionName}>{sectionName}</p>
        <br />
        <p className={classes.sectionTitle}>{title}</p>
        <p className={classes.sectionTitle}>{title2Line}</p>
        <br />
        {paragraphs.map((lines) => (
          <>
            <p className={classes.sectionText}>
              {lines.map((text) => (
                <>
                  {text}
                  <br />
                </>
              ))}
            </p>
            <br />
            <br />
          </>
        ))}
        {buttonText && (
          <Button
            type="submit"
            className={classes.sectionButton}
            variant="contained"
            color="primary"
            onClick={() => {
              location.hash = "#" + buttonSectionLinkName;
              scrollToHash(buttonSectionLinkName);
            }}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <div className={classes.imageContainer}>
        <img alt={"info-image"} className={classes.sectionImage} src={imageLink} />
      </div>
    </div>
  );
};
const SignUpForm = ({ client, history }: SignUpFormPropsInternal) => {
  const classes = useStyles({ mirror: false });
  const customAlert = useAlert();

  const currency = "$";
  const [accountEmail, setAccountEmail] = React.useState("");
  const [accountPassword, setAccountPassword] = React.useState("");
  const [hotelName, setHotelName] = React.useState("");
  const [name, setName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [onboardWay, setOnboardWay] = React.useState("");
  const [upsellEmail, setUpsellEmail] = React.useState("");
  const [rooms, setRooms] = React.useState<number>(170);
  const [avgUpsell, setAvgUpsell] = React.useState<number>(20);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [disableSignup, setDisableSignup] = React.useState(false);

  const numberUpsells = Math.round(rooms * 0.7);
  const revenueMonthly = Math.round(avgUpsell * 2 * numberUpsells);

  const [checkValidation, setCheckValidation] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleContactClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleContactClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const upsellHandler = async () => {
    await sendUpsellInfo(client, {
      email: upsellEmail,
      roomsAmount: rooms,
      averageUpsell: avgUpsell,
    });
    customAlert.show(
      {
        content: "A mail with results will be sent to you.",
        title: "Calculating your upsell revenue!",
      },
      {
        timeout: 0,
        type: "success",
      }
    );
  };
  const sellerRegisterHandler = async () => {
    setCheckValidation(true);

    const isAllFieldsFill = allFieldsFilledValidation({
      accountEmail,
      accountPassword,
    });
    if (isAllFieldsFill) {
      setDisableSignup(true);
      const seller = await registerSeller(client, {
        email: accountEmail,
        password: accountPassword,
        redirectUrl: confirmationUrl,
        seller: {
          contactEmail: accountEmail,
          contactPhone: phoneNumber,
          contactPerson: name,
          contactPersonTitle: jobTitle,
          onboardWay,
        },
        sellerInfo: {
          hotelName,
        },
      });
      setDisableSignup(false);
      if (seller.user && seller.user.id) {
        const user = await getUser(client, seller.user.id);

        if (user) {
          customAlert.show(
            {
              content: "A mail with further instructions will be sent to you.",
              title: "Your account has been registered!",
            },
            {
              timeout: 0,
              type: "success",
            }
          );
          history.push(paths.baseUrl);
        } else {
          customAlert.show(
            {
              content: "Something went wrong... Please try again.",
              title: "Error",
            },
            {
              timeout: 0,
              type: "error",
            }
          );
        }
      } else if (seller.errors && seller.errors.length > 0) {
        customAlert.show(
          {
            content: seller.errors[0].message,
            title: "Error",
          },
          {
            timeout: 0,
            type: "error",
          }
        );
        return;
      }
    } else {
      customAlert.show(
        {
          title: "Please fill in all fields",
        },
        {
          timeout: 0,
          type: "error",
        }
      );
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const hash = location.hash.substring(1);
      scrollToHash(hash);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location.hash]);

  const handleContactNowClick = () => {
    window.location.href = `mailto:${contactEmail}`;
    e.preventDefault();
  };

  return (
    <SEOWrapper pathname={window.location.pathname}>
      <TypedSiteQuery>
        {({ data }) => {
          return (
            <div>
              <div className={classes.header}>
                <HashLink className={classes.link} to={appPaths.baseUrl}>
                  <ReactSVG path={logoPath} className={classes.logo} />
                </HashLink>
                <div className={classes.headerButtons}>
                  {isMobile ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <>
                      <Link href="#retails-qr-code" color="inherit" className={classes.headerLink}>
                        QR Code
                      </Link>
                      <Link
                        href="#white-label-web-app"
                        color="inherit"
                        className={classes.headerLink}
                      >
                        Web App
                      </Link>
                      <Link
                        href="#calculate-upsell-revenue"
                        color="inherit"
                        className={classes.headerLink}
                      >
                        Upsells
                      </Link>
                      <Link
                        href="#calculate-upsell-revenue"
                        color="inherit"
                        className={classes.headerLink}
                      >
                        Calculator
                      </Link>
                      <Link href={dashboardUrl} color="inherit" className={classes.headerLink}>
                        Log in
                      </Link>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleContactClick}
                      >
                        <EmailIcon style={{ color: "#FF914D" }} />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleContactClose}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem key={option} selected={false} onClick={handleContactClose}>
                            {option}
                          </MenuItem>
                        ))}
                        <MenuItem selected={false} onClick={handleContactNowClick}>
                          <Button
                            type="submit"
                            className={classes.sectionButton}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleContactNowClick();
                            }}
                          >
                            Contact Now
                          </Button>
                        </MenuItem>
                      </Menu>
                      <Button
                        type="submit"
                        className={classes.sectionButton}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const hash = "get-started";
                          location.hash = "#" + hash;
                          scrollToHash(hash);
                        }}
                      >
                        Sign Up
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className={classes.firstSectionContainer}>
                <div className={classes.firstSection}>
                  <div className={classes.infoContainer}>
                    <p className={classes.firstSectionName}>Upsell Web App</p>
                    <p className={classes.firstSectionTitle}>Contactless Guests Experience</p>
                    {isMobile ? (
                      <p className={classes.sectionText}>
                        Allowing guests to book services and
                        <br />
                        add-ons throughout their stay by giving them continuous access to a
                        selection of
                        <br /> upselling deals.
                      </p>
                    ) : (
                      <p className={classes.sectionText}>
                        Allowing guests to book services and add-ons
                        <br />
                        throughout their stay by giving them continuous
                        <br />
                        access to a selection of upselling deals.
                      </p>
                    )}
                    <br />
                    <br />
                    <Button
                      type="submit"
                      className={classes.sectionButton}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const hash = "get-started";
                        location.hash = "#" + hash;
                        scrollToHash(hash);
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                  <div className={classes.videoContainer}>
                    <video
                      className={classes.video}
                      autoPlay
                      controls
                      muted
                      poster={videoThumbnail}
                    >
                      <source src={data.site.explanationVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className={classes.hotelsRoot}>
                  <ImagesCarousel imagesSources={partnerHotelImages} hotelName={"kek"} />
                </div>
              </div>
              <InfoSection
                sectionName={"QR code"}
                sectionLinkName={"retails-qr-code"}
                title={"Enhance Guests Experience"}
                paragraphs={[
                  [
                    "Make it easy for guests to book services and add-ons throughout their stay by giving them continuous access to a selection of giving them continuous access to a selection of upselling deals.",
                  ],
                  [
                    "Place your hotel QR code in your lobby, rooms, elevator or anywhere you think it’s relevant. Guests can scan it anytime and explore all the deals you’ve set up for in-stay upselling.",
                  ],
                ]}
                // buttonText={"SIGN UP TODAY"}
                buttonSectionLinkName={"get-started"}
                imageLink={retailsQRImagePath}
                mirror={true}
              />
              <InfoSection
                sectionName={"Hotel Web App"}
                sectionLinkName={"white-label-web-app"}
                title={"Automate In-stay Upselling"}
                paragraphs={[
                  [
                    "Guests can browse and pick from your hotel Web App throughout their stay. They can select any deal you have set up for in-stay upselling and book instantly.",
                  ],
                  [
                    "Once a guest books an offer via the QR code, they receive an automatic confirmation email with their booking details to show at the front desk or at the venue for check in.",
                  ],
                ]}
                // buttonText={"Calculate Upsell Revenue"}
                buttonSectionLinkName={"calculate-upsell-revenue"}
                imageLink={whiteLabelImagePath}
              />
              <InfoSection
                sectionName={"Tracking"}
                sectionLinkName={"central-system"}
                title={"Automate Team Operations\n"}
                paragraphs={[
                  [
                    "Automated in-stay upsell provides a contactless experience so your staff can handle requests for add-ons faster and more effectively.",
                  ],
                  [
                    "Both, the front desk and the corresponding department, receive a notification email and can access the request from your back office.",
                  ],
                  [
                    "Scan guests booking QR code to trigger an automatic check in, or do it manually from the back office.",
                  ],
                ]}
                buttonText={"Get Started"}
                buttonSectionLinkName={"get-started"}
                imageLink={centralSystemImagePath}
                mirror={true}
              />
              {/*<InfoSection*/}
              {/*  sectionName={"Customers Acquisition"}*/}
              {/*  sectionLinkName={"customer-acquisition"}*/}
              {/*  title={"Leverage OTAs and"}*/}
              {/*  title2Line={"third parties bookings"}*/}
              {/*  paragraphs={[*/}
              {/*    "Monetize overnight and day guests coming from third parties to generate direct reservations for your amenities and F&B outlets.",*/}
              {/*    "Acquire OTA and other apps customers contact details to enroll in your EDM campaigns and loyalty program.",*/}
              {/*  ]}*/}
              {/*  buttonText={"SIGN UP TODAY"}*/}
              {/*  buttonSectionLinkName={"get-started"}*/}
              {/*  imageLink={customerAcquisitionImagePath}*/}
              {/*/>*/}
              <div className={classes.finalSection}>
                <div id="calculate-upsell-revenue" className={classes.hotelRevenueContainer}>
                  <p className={classes.sectionName}>Upsells</p>
                  <p className={classes.sectionTitle} style={{ marginBottom: "5px" }}>
                    Calculate your{" "}
                    <span className={classes.sectionTitle} style={{ color: "#FF914D" }}>
                      Upsell Revenue
                    </span>
                  </p>
                  {/*<p className={classes.sectionText}>You only pay when we make you money!</p>*/}
                  <br />
                  <div className={classes.horizontalInput}>
                    <p className={classes.labelText}>Number of rooms at your property</p>
                    <TextField
                      variant="filled"
                      type="number"
                      value={rooms}
                      onChange={(event) => setRooms(Number(event.target.value))}
                    />
                  </div>
                  <div className={classes.horizontalInput}>
                    <p className={classes.labelText}>Average upsell price</p>
                    <TextField
                      variant="filled"
                      type="number"
                      value={avgUpsell}
                      onChange={(event) => setAvgUpsell(Number(event.target.value))}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ background: "rgba(0, 0, 0, 0.04)" }}
                          >
                            {currency}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.horizontalInput}>
                    <p className={classes.labelText}>Number of Upsells per month:</p>
                    <TextField variant="filled" type="number" value={numberUpsells} disabled />
                  </div>
                  <div className={classes.horizontalInput}>
                    <p className={classes.labelText}>Upsell Revenue per month:</p>
                    <TextField
                      variant="filled"
                      type="number"
                      value={revenueMonthly}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{currency}</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.emailUpsellContainer}>
                    <p className={classes.labelText}>
                      Enter your email to see your monthly upsells and revenue result *
                    </p>
                    <div className={classes.emailUpsellInput}>
                      <TextField
                        variant="filled"
                        label="Email"
                        value={upsellEmail}
                        onChange={(event) => setUpsellEmail(event.target.value)}
                      />
                      <Button
                        className={classes.sectionButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={upsellHandler}
                      >
                        REQUEST PRICING
                      </Button>
                    </div>
                  </div>
                </div>
                <div id="get-started" className={classes.hotelFormContainer}>
                  <div className={classes.paymentDetailsContainer}>
                    <p className={classes.sectionTitle}>Get started</p>
                    <div className={classes.pointLine}>
                      <img alt={"point"} className={classes.pointImage} src={tickOrangeImagePath} />
                      <p className={classes.sectionText}>
                        30 days FREE TRIAL with unlimited upsells
                      </p>
                    </div>
                    <div className={classes.singUpFormContainer}>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Hotel Name *"
                          value={hotelName}
                          onChange={(event) => setHotelName(event.target.value)}
                        />
                        {hotelName === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Hotel Name is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Email *"
                          value={accountEmail}
                          onChange={(event) => setAccountEmail(event.target.value.toLowerCase())}
                        />
                        {accountEmail === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Email is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Password *"
                          value={accountPassword}
                          onChange={(event) => setAccountPassword(event.target.value)}
                        />
                        {accountPassword === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Password is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Name *"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                        {name === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Name is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Phone Number *"
                          value={phoneNumber}
                          onChange={(event) => setPhoneNumber(event.target.value)}
                        />
                        {phoneNumber === "" && checkValidation ? (
                          <div className={classes.errorValidation}>Phone Number is required</div>
                        ) : (
                          <div className={classes.textFieldPad} />
                        )}
                      </div>
                      <div className={classes.textField}>
                        <TextField
                          variant="filled"
                          label="Job Title"
                          value={jobTitle}
                          onChange={(event) => setJobTitle(event.target.value)}
                        />
                        <div className={classes.textFieldPad} />
                      </div>
                      <div className={classes.textField}>
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel id="demo-simple-select-filled-label">
                            How did you hear about DailyPass?
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={onboardWay}
                            onChange={(event) => setOnboardWay(event.target.value as string)}
                          >
                            <MenuItem value="Hotel Association">Hotel Association</MenuItem>
                            <MenuItem value="Social Media">Social Media</MenuItem>
                            <MenuItem value="Referral">Referral</MenuItem>
                            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                            <MenuItem value="Word of Mouth">Word of Mouth</MenuItem>
                            <MenuItem value="Corporate Partnership">Corporate Partnership</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                        </FormControl>
                        <div className={classes.textFieldPad} />
                        <p>
                          By creating an account, you agree to our{" "}
                          <a
                            href={paths.termsUrl}
                            style={{ color: "blue", textDecoration: "underline" }}
                            target="_blank"
                          >
                            Terms
                          </a>{" "}
                          and have read and acknowledge the{" "}
                          <a
                            href="https://www.intuit.com/privacy/statement/"
                            style={{ color: "blue", textDecoration: "underline" }}
                            target="_blank"
                          >
                            Global Privacy Statement
                          </a>
                          .
                        </p>
                      </div>
                      <br />
                      <br />
                      <Button
                        className={classes.sectionButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={sellerRegisterHandler}
                        disabled={disableSignup}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </TypedSiteQuery>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText
              primary={"Web App"}
              onClick={() => {
                handleDrawerClose();
                location.hash = "#white-label-web-app";
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"QR Code"}
              onClick={() => {
                handleDrawerClose();
                location.hash = "#retails-qr-code";
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"Upsells"}
              onClick={() => {
                handleDrawerClose();
                location.hash = "#calculate-upsell-revenue";
              }}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"Log in"}
              onClick={() => {
                handleDrawerClose();
                location.href = dashboardUrl;
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={`Email: ${contactEmail}`} />
          </ListItem>
          <ListItem button>
            <Button
              type="submit"
              className={classes.sectionButton}
              variant="contained"
              color="primary"
              onClick={() => {
                handleDrawerClose();
                location.hash = "#get-started";
              }}
            >
              Sign Up
            </Button>
          </ListItem>
          <ListItem button>
            <Button
              type="submit"
              className={classes.sectionButton}
              variant="contained"
              color="primary"
              onClick={() => {
                handleContactNowClick();
              }}
            >
              Contact Now
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </SEOWrapper>
  );
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  header: {
    width: "100%",
    height: "10vh",
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10%",
    [theme.breakpoints.down("xs")]: {
      height: "5rem",
    },
  },
  link: {
    display: "inline-block",
    padding: "0 0.5rem",
  },
  logo: {
    height: "6vw",

    "& > div": {
      height: "100%",

      "& svg": {
        height: "100%",
        width: "auto",

        [theme.breakpoints.down("xs")]: {
          height: "5rem",
          transform: "translateY(-32%)",
        },

        "& path": {
          fill: "#FFBD59",
        },
      },
    },
  },
  headerButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    columnGap: "20px",
  },
  section: (props) => ({
    margin: "10vh 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "0 10%",
    columnGap: "5%",
    flexDirection: props.mirror ? "row-reverse" : "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  }),
  infoContainer: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
      marginBottom: "10px",
    },
  },
  imageContainer: {
    height: "100%",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  sectionName: {
    fontFamily: "sans-serif",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#FF914D",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  sectionTitle: {
    fontFamily: "sans-serif",
    color: "black",
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    fontWeight: 900,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
    },
  },
  firstSectionTitle: {
    fontFamily: "sans-serif",
    color: "black",
    fontSize: "2rem",
    lineHeight: "2rem",
    fontWeight: 900,
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
    },
  },
  sectionText: {
    fontFamily: "sans-serif",
    color: "black",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      lineHeight: "1.2rem",
      width: "100%",
    },
  },
  sectionImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  sectionButton: {
    borderRadius: "10px",
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#FF914D",
    padding: "0.8rem",
    width: "fit-content",
    "& .MuiButton-label": {
      fontFamily: "sans-serif",
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: "1.4rem",
    },
  },
  firstSectionContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FEFCFC",
    flexDirection: "column",
    padding: "10vh 10%",
    [theme.breakpoints.down("xs")]: {
      padding: "5vh 10% 10vh 10%",
    },
  },
  firstSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    columnGap: "5%",
    marginBottom: "5vh",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  firstSectionName: {
    fontFamily: "sans-serif",
    fontSize: "2.8rem",
    lineHeight: "2.8rem",
    fontWeight: "bold",
    color: "#FF914D",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: "2.0rem",
      lineHeight: "2.0rem",
    },
  },
  videoContainer: {
    height: "40vh",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  video: {
    height: "100%",
    width: "100%",
  },
  pointLinesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  pointLine: {
    display: "flex",
    marginBottom: "5px",
    alignItems: "center",
  },
  pointImage: {
    height: "1.6rem",
    objectFit: "contain",
    marginRight: "10px",
  },
  imageList: {
    flexWrap: "nowrap",
  },
  hotelsRoot: {
    width: "70%",
    "& > div": {
      height: "auto",
      alignItems: "center",
      "& .alice-carousel__wrapper": {
        height: "10vh",
        "& li": {
          height: "100%",
          "& img": {
            height: "100%",
            width: "100%",
            objectFit: "contain",
            opacity: "0.3",
          },
        },
      },
      "& > div": {
        margin: "5px",
        "& > div": {
          backgroundColor: "unset",
        },
      },
    },
  },
  headerLink: {
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  drawer: {
    width: "100vw",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "100vw",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
  },
  cardContainer: {
    margin: "2vw 4vw 3vw",
    [theme.breakpoints.down("md")]: {
      margin: "1.5vw 3vw 1.5vw",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "4vw 2vw",
    },
  },
  card: {
    [theme.breakpoints.down("xs")]: {
      padding: 12,
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: "0.675rem",
    left: 10,
    position: "absolute",
    top: 5,
  },
  errorValidation: {
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    fontWeight: "bold",
    paddingTop: "0.5rem",
    alignSelf: "flex-start",
  },
  paymentDetailsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2vw",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      marginTop: "0",
    },
    "& > p": {
      marginBottom: "20px",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "10px",
    "& > div": {
      width: "100%",
    },
    "& .MuiInputLabel-formControl": {
      color: "#A8ABA7",
    },
    [theme.breakpoints.down("xs")]: {
      "&:first-child": {
        marginTop: "3vw",
      },
      marginBottom: "3vw",
    },
  },
  textFieldPad: {
    height: "1.25rem",
  },
  hotelFormContainer: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: "#FFEFE4",
    padding: "10vh 10%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
      marginBottom: "10px",
    },
  },
  finalSection: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  hotelRevenueContainer: {
    height: "100%",
    width: "50%",
    padding: "10vh 5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
      marginBottom: "10px",
    },
  },
  singUpFormContainer: {
    width: "100%",
    background: "white",
    padding: "50px 5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  horizontalInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& > div": {
      width: "30%",
      "& > div": {
        background: "rgba(0, 0, 0, 0.04)",
        "& > input": {
          background: "rgba(0, 0, 0, 0)",
        },
      },
      "& > .Mui-disabled": {
        background: "#FFEFE4",
        "& > input": {
          color: "#FF914D",
        },
        "& > .MuiInputAdornment-filled": {
          "& > p": {
            color: "#FF914D",
          },
        },
      },
    },
  },
  labelText: {
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  emailUpsellInput: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > div": {
      width: "70%",
    },
  },
}));

export default withApollo(withRouter(SignUpForm));
