import React from "react";

import HotelDetailsQuery from "./HotelDetailsQuery";
import { SEOWrapper } from "@temp/components/SEO";
import { makeCorrectDateUrl } from "@temp/utils";
import { MainHeaderContext } from "@temp/app/MainHeaderProvider";

const HotelDetails = ({ match: { params }, location, history }) => {
  const date = makeCorrectDateUrl(history);


  if (params.hotelSlug === 'akara-hotel-1') {
    window.location.href = 'https://b2b.upguest.io/hotel/akara-hotel-2';
  } else if (params.hotelSlug === 'sindhorn-midtown-hotel-bangkok-vignette-collection-1') {
    window.location.href = 'https://b2b.upguest.io/hotel/sindhorn-midtown-hotel-bangkok-vignette-collection-1';
  } else if (params.hotelSlug === 'chatrium-residence-sathon-bangkok') {
    window.location.href = 'https://b2b.upguest.io/hotel/chatrium-residence-sathon-bangkok';
  } else if (params.hotelSlug === 'the-qlub-by-silq-hotel-residence') {
    window.location.href = 'https://b2b.upguest.io/hotel/silq-hotel-residence';
  }

  return (
    <MainHeaderContext.Consumer>
      {({ update }) => {
        return (
          <SEOWrapper pathname={location.pathname}>
            <HotelDetailsQuery
              client={null}
              hotelSlug={params.hotelSlug}
              date={date}
              updateHeader={update}
            />
          </SEOWrapper>
        );
      }}
    </MainHeaderContext.Consumer>
  );
};

export default HotelDetails;
