import * as React from "react";
import { BillProps } from "@temp/checkout/components/Bill/types";
import "./scss/index.scss";
import { useIntl } from "react-intl";
import moment from "moment";
import { getCurrencySymbol } from "@temp/utils";

const Line = ({ title, value = "", extraClasses = "", extraTitleClasses = "" }) => {
  return (
    <div className="line" key={title}>
      <div className={"line-container " + extraClasses}>
        <span className={extraTitleClasses}>{title}</span>
        <span>{value}</span>
      </div>
    </div>
  );
};

function moneyDisplay(value) {
  return Number.isInteger(value) ? value : Number.parseFloat(value).toFixed(2);
}

// ToDo: change this money representation
const Bill: React.FC<BillProps> = ({ voucherData, voucherTitle, currency }) => {
  const formattedMessageIdPrefix = "src.checkout.components.Bill.";
  const intl = useIntl();
  const dateTitle = `${intl.formatMessage({
    id: `${formattedMessageIdPrefix}dailypassFor`,
  })} ${moment(voucherData.selectedDate, "DD MMM YYYY")
    .locale(intl.locale)
    .format("dddd, DD MMMM YYYY")}`;
  const adultTitle = `${intl.formatMessage({ id: "text.adults" })} x ${voucherData.adultQuantity}`;
  const childrenTitle = `${intl.formatMessage({ id: "text.children" })} x ${
    voucherData.childQuantity
  }`;
  const totalTitle = intl.formatMessage({ id: `${formattedMessageIdPrefix}total` });

  return (
    <div className="bill-container">
      <Line title={dateTitle} extraClasses="date-title" />
      <Line title={voucherTitle} value={""} />
      <Line
        title={adultTitle}
        value={`${getCurrencySymbol(currency)}${moneyDisplay(voucherData.adultTotal)}`}
      />
      <Line
        title={childrenTitle}
        value={`${getCurrencySymbol(currency)}${moneyDisplay(voucherData.childTotal)}`}
      />
      <Line
        title={totalTitle}
        value={`${getCurrencySymbol(currency)}${moneyDisplay(voucherData.total)}`}
        extraClasses="total"
        extraTitleClasses="total__title"
      />
    </div>
  );
};

export default Bill;
