import gql from "graphql-tag";

const GET_REVIEWS = gql`
  query Reviews($filter: ReviewFilterInput, $first: Int) {
    reviews(filter: $filter, first: $first) {
      totalCount
      edges {
        cursor
        node {
          id
          firstName
          lastName
          text
          created
          starRating
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export async function getReviews(client, { filter, first }) {
  const { data, loading } = await client.query({
    query: GET_REVIEWS,
    variables: { filter, first },
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}
