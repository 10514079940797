import * as React from "react";
import { maybe } from "@temp/core/utils";
import noPhotoImg from "images/no-photo.svg";
import { HotelImageInfoProps } from "@temp/checkout/components/HotelImageInfo/types";
import "./scss/index.scss";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

interface HotelImageInfoPropsInner
  extends Omit<HotelImageInfoProps, "voucherPrice">,
    Partial<Pick<HotelImageInfoProps, "voucherPrice">> {}

const HotelImageInfo = ({
  mainImageUrl,
  hotelName,
  voucherTitle,
}: HotelImageInfoPropsInner) => {
  return (
    <div className="hotel-image-info-container">
      <div className="image-container">
        <img
          src={maybe(() => mainImageUrl, noPhotoImg)}
          alt=""
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.style.visibility = "hidden";
            target.style.height = "40vh";
          }}
        />
        <div className="hotel-name-overlay">
          <div className="hotel-info-container">
            <div className="hotel-name">
              <ResponsiveEllipsis
                text={hotelName}
                maxLine="2"
                ellipsis="..."
                basedOn="words"
                trimRight
              />
            </div>
            <div className="voucher-buying">{voucherTitle}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelImageInfo;
