import gql from "graphql-tag";

const CREATE_ORDER = gql`
  mutation ($input: OrderCreateInput!) {
    orderCreate(input: $input) {
      order {
        token
      }
      errors {
        field
        message
      }
    }
  }
`;

export async function orderCreate(client, input) {
  const {
    data: { orderCreate },
  } = await client.mutate({
    mutation: CREATE_ORDER,
    variables: { input },
  });

  return orderCreate;
}
