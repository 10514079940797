import React from "react";

import { withApollo } from "react-apollo";

import { Loader } from "src/components";

import HotelInfoReviewsView from "./HotelInfoReviewsView";

import { getReviews } from "./reviewsRequests";

const HotelInfoReviewsQuery = ({ client, params: { sellerId } }) => {
  const [loading, setLoading] = React.useState(true);
  const [reviews, setReviews] = React.useState({ reviews: { edges: [] } });

  React.useEffect(() => {
    (async () => {
      const getReviewsResult = await getReviews(client, {
        filter: {
          seller_Id: sellerId,
          status: "Active",
        },
        first: 100,
      });

      const { data, loading } = getReviewsResult;
      setReviews(data);
      setLoading(loading);
    })();
  }, []);

  return loading ? (
    <Loader />
  ) : reviews?.reviews?.edges ? (
    reviews.reviews.edges.length > 0 ? (
      <HotelInfoReviewsView reviewsData={reviews.reviews} />
    ) : null
  ) : null;
};

export default withApollo(HotelInfoReviewsQuery);
