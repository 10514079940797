import gql from "graphql-tag";

import { TypedQuery } from "../../core/queries";

const getSite = gql`
  query Site {
    site {
      faq
    }
  }
`;

export const TypedSiteQuery = TypedQuery(getSite);
