import React from "react";
import "./scss/index.scss";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

// @ts-ignore
const lastElementStyle = {
  marginTop: "1.3rem",
};

// @ts-ignore
const middleElementStyle = {
  marginTop: "2.5rem",
};
const HowItWorksArea = () => {
  // const classes = useStyles();

  // let calendarPath;
  // let poolPath;
  // let voucherPath;

  // calendarPath = calendarMobilePath;
  // poolPath = poolMobilePath;
  // voucherPath = voucherMobilePath;

  return (
    <div className="hotel-details-how-it-works">
      <div id="howitworks" className="hotel-details-how-it-works-title">
        <FormattedMessage id="hoteldetails.slogan1" />
      </div>
      <div className="hotel-details-how-it-works-title-2">
        {/*<FormattedMessage id="hoteldetails.slogan2" />*/}
      </div>
      {/* <div className="directions">
        <div className={classes.leftStyle}>
          <ReactSVG
            className={classes.howItWorksSvg}
            path={calendarPath}
          />
          <div className={classes.leftAndRightStyle}><FormattedMessage id="hoteldetails.choose" /></div>
        </div>
        <div>
          <ReactSVG path={voucherPath} className={classes.middleElementStyle} />
          <div className={classes.DrinksBookStyle}><FormattedMessage id="hoteldetails.select" /></div>
        </div>
        <div>
          <ReactSVG path={poolPath} className={classes.lastElementStyle} />
          <div className={classes.leftAndRightStyle}><FormattedMessage id="hoteldetails.enjoy" /></div>
        </div>
      </div>
      */}
    </div>
  );
};

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  howItWorksSvg: {
    transform: "scale(2.5)",
    width: "3rem",
    height: "5rem",
    [theme.breakpoints.up("xs")]: {
      transform: "scale(1.5)",
    },
  },
  leftStyle: {
    // marginLeft: '4vw',
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: "1.3rem"
    // }
  },
  leftAndRightStyle: {
    marginBottom: "15px",
  },
  DrinksBookStyle: {
    marginBottom: "15px",
  },
  middleElementStyle: {
    marginTop: "0rem",
    transform: "scale(2.5)",
    width: "3rem",
    height: "5rem",
    [theme.breakpoints.up("xs")]: {
      transform: "scale(1.5)",
    },
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "1.4rem"
    // }
  },
  lastElementStyle: {
    marginTop: "0rem",
    transform: "scale(2.5)",
    width: "3rem",
    height: "5rem",
    [theme.breakpoints.up("xs")]: {
      transform: "scale(1.5)",
    },
    [theme.breakpoints.down("sm")]: {
      // marginTop: "0.3rem",
    },
  },
}));

export default HowItWorksArea;
