import React from "react";
import { FormattedMessage } from "react-intl";

import "./scss/index.scss";
import srcPassBuffet from "src/images/passBuffet.png";
import srcPassPool from "src/images/passPool.png";
import srcPassSkyBar from "src/images/passSkyBar.png";
import { TypedPassTypesQuery } from "@temp/components/Home/queries";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const SelectPass = () => {
  return (
    <TypedPassTypesQuery>
      {({ data }) => {
        return (
          <div className="selectpass">
            <span>
              <FormattedMessage id="selectPass.title" />
            </span>
            <div className="selectpass-images">
              {data?.passTypes
                ?.filter((passType) => passType.showOnLanding)
                ?.map((passType) => (
                  <Card className="img-container">
                    <CardActionArea>
                      <CardMedia
                        className="pass-type-image"
                        image={passType.landingImage}
                        title="Type image"
                      />
                      <CardContent>
                        <div className="selectpass-title">{passType.landingName}</div>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          display="block"
                        >
                          {passType.landingDescription}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
            </div>
            <div className="book-pool">
              <FormattedMessage id="explore.main1" />
              <br />
              <FormattedMessage id="explore.main2" />
            </div>
          </div>
        );
      }}
    </TypedPassTypesQuery>
  );
};

export default SelectPass;
