import { ApolloClient, MutationOptions as ApolloMutationOptions } from "apollo-client";
import gql from "graphql-tag";
import * as Auth from "./auth";
import * as User from "./user";

import { SetPassword, SetPasswordVariables } from "./types/SetPassword";

import { TokenAuth, TokenAuthVariables } from "./types/TokenAuth";

import { PasswordChange, PasswordChangeVariables } from "./types/PasswordChange";

export type MutationOptions<TData, TVariables> = Omit<
  ApolloMutationOptions<TData, TVariables>,
  "mutation"
>;

// TODO: Add ability to pass custom fragments to mutations
export const MUTATIONS = {
  PasswordChange: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<PasswordChange, PasswordChangeVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${User.changeUserPassword}
      `,
      ...options,
    }),
  SetPassword: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<SetPassword, SetPasswordVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${User.setPassword}
      `,
      ...options,
    }),
  TokenAuth: <TCacheShape>(
    client: ApolloClient<TCacheShape>,
    options: MutationOptions<TokenAuth, TokenAuthVariables>
  ) =>
    client.mutate({
      mutation: gql`
        ${Auth.tokenAuthMutation}
      `,
      ...options,
    }),
};

export type MUTATIONS = typeof MUTATIONS;
