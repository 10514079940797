import * as React from "react";
import ReactSVG from "react-svg";
import moment from "moment-timezone";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

import * as paths from "@temp/app/routes/paths";
import Calendar from "@temp/components/Home/Board/Calendar";
// import Location from "@temp/components/Home/Board/Location";
import chevronDownPath from "images/chevron-down.svg";
import dates from "../Home/Board/Calendar/dates";
import mapIconPath from "images/map-icon.svg";
import { getDefaultDate, storeDate } from "@temp/utils";
import Country from "@temp/components/Home/Board/Country";

const GET_LOCATIONS = gql`
  query {
    locations {
      name
    }
  }
`;

export async function getLocations(client) {
  const { data, loading } = await client.query({
    query: GET_LOCATIONS,
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}

const GET_COUNTRIES = gql`
  query {
    countries {
      code
      name
    }
  }
`;

export async function getCountries(client) {
  const { data, loading } = await client.query({
    query: GET_COUNTRIES,
    fetchPolicy: "no-cache",
  });

  return { data, loading };
}

export const timeForStorageInputs = 86400000;

const SearchPanel = ({ className, locationName, countryStartingCode = "TH", client }) => {
  const intl = useIntl();

  const calendarDivRef = React.useRef(null);
  // const locationDivRef = React.useRef(null);
  const countryDivRef = React.useRef(null);

  const [date, setDate] = React.useState(null);
  const [defaultMonthIndex, setDefaultMonthIndex] = React.useState(null);
  const [isCalendarVisible, setIsCalendarVisible] = React.useState(false);
  // const [isLocationVisible, setIsLocationVisible] = React.useState(false);
  // const [location, setLocation] = React.useState(locationName);
  // const [locations, setLocations] = React.useState([locationName]);

  const [isCountryVisible, setIsCountryVisible] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState(countryStartingCode);
  const [countries, setCountries] = React.useState([]);

  const setDateWithLocalStorage = (dt) => {
    setDate(dt);
    storeDate(dt);
  };

  // check for date
  React.useEffect(() => {
    // getLocations(client).then((r) =>
    //   setLocations(r.data.locations.map((location) => location.name))
    // );
    getCountries(client).then((r) => setCountries(r.data.countries));
    // check if the local storage value for date is fresh
    const storagePickDate = localStorage.getItem("pickDate");
    const storagePickDateMoment = localStorage.getItem("pickDateMoment");

    let isStale = null;
    let month = null;

    if (storagePickDate) {
      // if the difference is more one day than stale
      isStale = storagePickDateMoment
        ? Number(new Date()) - Number(storagePickDateMoment) > timeForStorageInputs
        : false;

      if (!isStale) {
        // if is not stale than localStorage value is used
        // but check if this date is exist in calendar and is active

        month = dates.find((month) => {
          return month.find((row) => {
            return row.find((item) => {
              return item.date && item.isActive && item.date.format("D MMMM Y") === storagePickDate;
            });
          });
        });

        if (month) {
          const monthIndex = dates.findIndex((item) => {
            return item === month;
          });
          setDefaultMonthIndex(monthIndex);

          setDate(storagePickDate);

          return;
        }
      }
    }

    // default date
    if (
      !isStale &&
      (month === null || month === undefined) &&
      !(storagePickDate === null || storagePickDate === undefined)
    ) {
      setDateWithLocalStorage(storagePickDate);
    } else {
      setDateWithLocalStorage(getDefaultDate());
    }
  }, []);

  return (
    <div className={className}>
      {/*<div*/}
      {/*  ref={locationDivRef}*/}
      {/*  onMouseDown={(event) => event.preventDefault()}*/}
      {/*  className="selector"*/}
      {/*>*/}
      {/*  <span onClick={() => setIsLocationVisible(true)}>{location}</span>*/}
      {/*  <ReactSVG onClick={() => setIsLocationVisible(true)} path={mapIconPath} />*/}
      {/*  {isLocationVisible && (*/}
      {/*    <Location*/}
      {/*      location={location}*/}
      {/*      locations={locations}*/}
      {/*      setLocation={setLocation}*/}
      {/*      setIsLocationVisible={setIsLocationVisible}*/}
      {/*      locationDivRef={locationDivRef}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
      <div ref={countryDivRef} onMouseDown={(event) => event.preventDefault()} className="selector">
        <span onClick={() => setIsCountryVisible(true)}>
          {countries?.find((country) => country.code === countryCode)?.name}
        </span>
        <ReactSVG onClick={() => setIsCountryVisible(true)} path={mapIconPath} />
        {isCountryVisible && (
          <Country
            countryCode={countryCode}
            countries={countries}
            setCountryCode={setCountryCode}
            setIsCountryVisible={setIsCountryVisible}
            countryDivRef={countryDivRef}
          />
        )}
      </div>

      <div
        ref={calendarDivRef}
        onMouseDown={(event) => event.preventDefault()}
        className="selector"
      >
        <span onClick={() => setIsCalendarVisible(true)}>
          {date && moment(date, "D MMMM Y").locale(intl.locale).format("D MMMM Y")}
        </span>
        <ReactSVG onClick={() => setIsCalendarVisible(true)} path={chevronDownPath} />
        {isCalendarVisible && (
          <Calendar
            setDate={setDateWithLocalStorage}
            setIsCalendarVisible={setIsCalendarVisible}
            calendarDivRef={calendarDivRef}
            defaultMonthIndex={defaultMonthIndex}
            timeForStorageInputs={timeForStorageInputs}
          />
        )}
      </div>

      {className === "search-panel-hotel-list" && (
        <a
          className="search-button"
          href={`${paths.hotelsList}/?date=${date}&country=${countryCode}`}
        >
          <button>
            <FormattedMessage id="mainimage.search" />
          </button>
        </a>
      )}
      {className !== "search-panel-hotel-list" && (
        <Link
          className="search-button"
          to={`${paths.hotelsList}/?date=${date}&country=${countryCode}`}
        >
          <button>
            <FormattedMessage id="mainimage.search" />
          </button>
        </Link>
      )}
    </div>
  );
};

export default withApollo(SearchPanel);
