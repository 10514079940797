import * as React from "react";

import { FormError } from "../Form";

import { useUserDetails } from "@sdk/react";

import "./scss/index.scss";
import { defaultLocale, messages } from "@temp/components/Locale/Locale";
import { useLocalStorage } from "@hooks";

type Style = "white" | "grey";

interface IClassNameArgs {
  errors?: FormError[];
  iconLeft?: React.ReactNode;
  styleType?: Style;
}

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  errors?: FormError[];
  helpText?: string;
  label?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  styleType?: Style;
}

const generateClassName = ({ errors, iconLeft, styleType }: IClassNameArgs) => {
  const baseClass = "input__field";
  const errorsClass = errors && errors.length ? " input__field--error" : "";
  const iconLeftClass = iconLeft ? " input__field--left-icon" : "";
  const styleTypeClass = styleType === "grey" ? " input__field--grey" : "";

  return baseClass.concat(errorsClass, iconLeftClass, styleTypeClass);
};

const getMessageForLanguage = (label, locale) => {
  let returnValue = label;

  switch (label.toLowerCase()) {
    case "first name":
      returnValue = messages[locale]["text.first_name"];
      break;

    case "last name":
      returnValue = messages[locale]["text.last_name"];
      break;

    case "email address (you will get your dailypass confirmation here)":
      returnValue = messages[locale]["text.checkout.email_address"];
      break;

    case "phone number":
      returnValue = messages[locale]["text.phone_number"];
      break;
  }

  return returnValue;
};
const TextField: React.FC<TextFieldProps> = ({
  label = "",
  iconLeft,
  iconRight,
  errors,
  helpText,
  styleType = "white" as Style,
  ...rest
}) => {
  const { storedValue: locale } = useLocalStorage("locale", defaultLocale);
  const { data: user } = useUserDetails();

  const defaultValues: any = {};

  if (user?.email && rest.name === "email") {
    defaultValues.defaultValue = user.email;
  }

  return (
    <div className="input">
      {iconLeft ? <span className="input__icon-left">{iconLeft}</span> : null}
      {iconRight ? (
        <span className="input__icon-right">{iconRight}</span>
      ) : null}
      <div className="input__content">
        <input
          {...rest}
          {...defaultValues}
          className={generateClassName({ errors, iconLeft, styleType })}
        />
        {label ? (
          <span className="input__label">
            {getMessageForLanguage(label, locale)}
          </span>
        ) : null}
      </div>
      {errors && (
        <span className="input__error">
          {errors.map((error) => error.message).join(" ")}
        </span>
      )}
      {helpText && <span className="input__help-text">{helpText}</span>}
    </div>
  );
};

export default TextField;
