import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import googleMapsImage from "src/images/GoogleMapsImage.png";
import { isMobile } from "react-device-detect";

const GoogleMapsEmbedded = ({googleEmbeddedLink}) => {
  if (isMobile) {
    return <iframe
        src={googleEmbeddedLink}
        width="100%" height="100%" style={{ border: "0", marginBottom: "25px" }} loading="lazy"

        referrerPolicy="no-referrer-when-downgrade"></iframe>
  } else {
    return <iframe
        src={googleEmbeddedLink}
        width="100%" height="135%" style={{ border: "0", marginBottom: "25px" }} loading="lazy"

        referrerPolicy="no-referrer-when-downgrade"></iframe>
  }
}

const HotelInfoMap = ({ lat, long, googleEmbeddedLink }) => {
  const classes = useStyles();
  console.log('HotelInfoMap called')

  if (googleEmbeddedLink.length === 0) {
    return (
        <div className={classes.mapContainer}>
          <img src={googleMapsImage} />
        </div>
    );
  } else {
    return (
        <div className={classes.mapContainer}>
          <GoogleMapsEmbedded googleEmbeddedLink={googleEmbeddedLink}/>
        </div>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "17rem",
    paddingRight: "0rem",

    [theme.breakpoints.down("sm")]: {
      height: "15rem",
    },
  },
  marker: {
    position: "absolute",
    width: "40px",
    height: "40px",
    left: "-20px",
    top: "-25px",

    borderRadius: "40px",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "4px",

    "& svg": {
      height: "1.55rem",
      width: "auto",
      position: "absolute",
      cursor: "pointer",

      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

export default HotelInfoMap;
