import React from "react";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";

import HotelHeader from "./HotelHeader";
import HotelInfo from "./HotelInfo";
import HotelInfoReviewsQuery from "@temp/components/HotelDetails/HotelInfo/HotelInfoReviewsQuery";
import HotelVouchers from "./HotelVouchers";
import HowItWorksArea from "@temp/components/HotelDetails/HowItWorksArea/HowItWorksArea";
import ImagesCarousel from "./ImagesCarousel";
import { SEOWrapper } from "@temp/components/SEO";
import BecomePartnerButton from "@temp/components/BecomePartnerButton/BecomePartnerButton";

const HotelDetailsView = ({
  seller: {
    id: sellerId,
    reservationEmail,
    sellerInfo: {
      hotelName,
      hotelDescription: hotelDescriptionEn,
      starRating,
      hotelAddress: hotelAddressEn,
      latitude,
      longitude,
      area: {
        name: areaEn,
        location: { name: locationName },
      },
      mainHotelImage,
      generalTermsTitle,
      generalTermsText,
      mapsEmbeddedLink
    },
    acceptingBookings,
    shaCertified,
    passTypes,
  },
  venuePasses,
  hotelSlug,
  selectedDate,
  setSelectedDate,
  imagesSources,
}) => {
  const classes = useStyles();
  const image = mainHotelImage !== undefined ? mainHotelImage : null;

  const areaName = areaEn;
  const hotelAddress = hotelAddressEn;
  const hotelDescription = hotelDescriptionEn;

  const vouchersComponent = (
    <div className={classes.voucher}>
      <HotelVouchers
        acceptingBookings={acceptingBookings}
        hotelName={hotelName}
        hotelSlug={hotelSlug}
        locationName={locationName}
        mainHotelImage={mainHotelImage}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        venuePasses={venuePasses}
        sellerId={sellerId}
        reservationEmail={reservationEmail}
      />
    </div>
  );

  const headerComponent = (
    <HotelHeader
      generalTermsText={generalTermsText}
      generalTermsTitle={generalTermsTitle}
      hotelDescription={hotelDescription}
      hotelName={hotelName}
      locationName={hotelAddress}
      shaCertified={shaCertified}
      starRating={starRating}
      passTypes={passTypes}
    />
  );

  const infoComponent = (
    <HotelInfo
      address={hotelAddress}
      lat={latitude}
      long={longitude}
      reservationEmail={reservationEmail}
      passTypes={passTypes}
      googleEmbeddedLink={mapsEmbeddedLink}
    />
  );

  return (
    <SEOWrapper pathname={location.pathname} image={image}>
      <ImagesCarousel
        imagesSources={[{ id: sellerId, url: mainHotelImage }, ...imagesSources]}
        hotelName={hotelName}
      />
      <HowItWorksArea />
      <div className={classes.gridContainer}>
        {isMobile ? (
          [headerComponent, vouchersComponent, infoComponent]
        ) : (
          <>
            <div>
              {headerComponent}
              {infoComponent}
            </div>
            {vouchersComponent}
          </>
        )}
      </div>
      <div className={classes.reviews}>
        <HotelInfoReviewsQuery client={null} params={{ sellerId }} />
      </div>
      <BecomePartnerButton email={reservationEmail} />
    </SEOWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  reviews: {
    padding: "4vw",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "3rem",
      padding: "4vw",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10vw",
      marginLeft: "0rem",
    },
  },
  divider: {
    height: "1px",
    width: "89%",
    backgroundColor: theme.customColors.grid,
    margin: "3rem 6rem",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "auto auto",
    padding: "1vw 4vw 4vw 4vw",
    gridTemplateAreas: `
          "a b"
          "b c"
        `,

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
      padding: "1vw 4vw 4vw 4vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-11vw",
      padding: "1vw 4vw 4vw 4vw",
    },
  },
  voucher: {
    gridColumn: "2 / 3",
    gridRow: "1 / 3",

    [theme.breakpoints.down("sm")]: {
      gridColumn: "1 / 2",
      gridRow: "2 / 3",
    },
  },
}));

export default HotelDetailsView;
