import React from "react";

import moment from "moment";

import { RouteComponentProps, withRouter } from "react-router-dom";
import * as paths from "src/app/routes/paths";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useIntl } from "react-intl";
import enLocale from "date-fns/locale/en-US";
import thLocale from "date-fns/locale/th";

// types are incorrect here. Correct them if possible
interface HotelVouchersDatePickerProps extends RouteComponentProps {
  selectedDate: any;
  setSelectedDate: any;
  hotelSlug: any;
  hotelLocation: any;
}

const HotelVouchersDatePicker = (props: HotelVouchersDatePickerProps) => {
  const intl = useIntl();

  const localeMap = {
    en: enLocale,
    th: thLocale,
  };

  const dateChangeHandler = (date) => {
    props.setSelectedDate(moment(date).format("D MMMM Y"));
    props.history.push(
      `${paths.hotelDetails}${props.hotelLocation}/${
        props.hotelSlug
      }?date=${moment(date).format("D MMMM Y")}`
    );
  };

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[intl.locale]}
    >
      <DatePicker
        value={props.selectedDate}
        onChange={dateChangeHandler}
        minDate={new Date()}
        format="dd/MM/yyyy"
        maxDate={new Date(Number(new Date()) + 31536000000)}
        autoOk={true}
        cancelLabel={""}
        okLabel={""}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withRouter(HotelVouchersDatePicker);
