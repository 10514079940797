import "./scss/index.scss";

import * as React from "react";
import { RouteComponentProps } from "react-router";

import { Offline, OfflinePlaceholder, Online, OverlayManager } from "../components";
import { CheckoutRoutes } from "./routes";
import MainHeader from "@temp/components/MainHeader";
import MainFooter from "@temp/components/MainFooter";
import { SEOWrapper } from "@temp/components/SEO";

const CheckoutApp: React.FC<RouteComponentProps> = ({
  history: {
    location: { pathname },
  },
}) => {
  return (
    <SEOWrapper pathname={window.location.pathname}>
      <div className="checkout">
        <header className="header-block">
          <MainHeader hideList />
        </header>
        <Online>
          {(() => {
            return <CheckoutRoutes />;
          })()}
        </Online>
        <Offline>
          <OfflinePlaceholder />
        </Offline>
        <OverlayManager />
        <MainFooter hideMap />
      </div>
    </SEOWrapper>
  );
};

export default CheckoutApp;
