import React from "react";
import "./scss/index.scss";

import { HashLink as Link } from "react-router-hash-link";

import * as appPaths from "src/app/routes";
import ReactSVG from "react-svg";
import logoPath from "src/images/logo-daily-pass-light.svg";
import engFlagSrc from "src/images/eng-flag.png";
import thaiFlagSrc from "src/images/thai-flag.png";

import { MainMenu } from "..";

import barsOpenPath from "src/images/bars-open.svg";
import { useLocalStorage } from "@hooks";
import { defaultLocale } from "@temp/components/Locale/Locale";
import { messages } from "../Locale/Locale";
import { isMobile } from "react-device-detect";
import { MainHeaderContext } from "@temp/app/MainHeaderProvider";

export interface MainHeaderProps {
  hideList?: boolean;
}

const MainHeader: React.FC<MainHeaderProps> = ({ hideList }) => {
  const { storedValue: locale, setValue: setLocale } = useLocalStorage("locale", defaultLocale);
  const [listItem, setListItem] = React.useState(null);

  let ulList;
  if (isMobile) {
    ulList = hideList ? [] : [];
  } else {
    ulList = hideList
      ? []
      : [
          {
            title: messages[locale]["header.howitworks"],
            path: appPaths.baseUrl + "#howitworks",
          },
          {
            title: messages[locale]["header.partner_sign_up"],
            path: appPaths.baseUrl + "sign-up",
          },
        ];
  }

  function setLanguage(lang) {
    setLocale(lang);
    window.location.reload();
  }

  return (
    <MainHeaderContext.Consumer>
      {({ onlyLogo, customLogo, reset }) => {
        function onLogoClick() {
          reset();
          setListItem(null);
        }

        return (
          <nav className="main-header">
            {customLogo ? (
              <div className="link-img">
                <img className="logo-img" src={customLogo} />
              </div>
            ) : (
              <Link className="link" to={appPaths.baseUrl}>
                <ReactSVG path={logoPath} className={"logo"} onClick={onLogoClick} />
              </Link>
            )}
            {!onlyLogo && (
              <>
                <ul className="middle-content">
                  {ulList.map((item, index) => (
                    <li
                      key={index}
                      className={item === listItem ? "current-list-item" : null}
                      onClick={() => setListItem(item)}
                    >
                      <Link to={item.path}>{item.title}</Link>
                    </li>
                  ))}
                </ul>

                <div className="flags">
                  <img src={engFlagSrc} onClick={() => setLanguage("en")} alt="" />
                  <img src={thaiFlagSrc} onClick={() => setLanguage("th")} alt="" />
                </div>
              </>
            )}

            <MainMenu />

            <ReactSVG path={barsOpenPath} className={"bars"} onClick={() => setListItem(null)} />
          </nav>
        );
      }}
    </MainHeaderContext.Consumer>
  );
};

export default MainHeader;
