import React from "react";

import SEOView from "@temp/components/SEO/SEOView";

const SEOViewWrapper = ({
  description,
  title,
  keywords,
  noindex,
  pathname,
  image,
  children,
}) => {
  if (noindex) {
    return (
      <SEOView
        description=""
        title=""
        keywords=""
        pathname=""
        noindex={true}
        image={null}
      >
        {children}
      </SEOView>
    );
  } else {
    return (
      <SEOView
        description={description}
        title={title}
        keywords={keywords}
        pathname={pathname}
        noindex={false}
        image={image}
      >
        {children}
      </SEOView>
    );
  }
};

export default SEOViewWrapper;
