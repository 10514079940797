import * as React from "react";
import { Helmet } from "react-helmet";

import { Consumer as MetaConsumer } from "./context";

const Consumer: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <MetaConsumer>
    {({ title, description, image, type, url, keywords, custom, noindex }) => (
      <>
        {noindex ? (
          <Helmet
            title={title}
            meta={[{ name: "robots", content: "noindex, nofollow" }, ...custom]}
          />
        ) : (
          <Helmet
            title={title}
            meta={[
              { name: "robots", content: "all" },
              { name: "description", content: description },
              { property: "og:url", content: url },
              { property: "og:title", content: title },
              { property: "og:description", content: description },
              { property: "og:type", content: type },
              { property: "og:image", content: image },
              { name: "keywords", content: keywords },
              ...custom,
            ]}
          />
        )}

        {children}
      </>
    )}
  </MetaConsumer>
);

export default Consumer;
