import React from "react";
import "./scss/index.scss";
import "./scss/hotelList.scss";
import * as paths from "src/app/routes/paths";
import mapIconPath from "images/map-icon.svg";

import { Rating } from "@material-ui/lab";

import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import SearchPanel from "@temp/components/SearchPanel";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { Loader } from "@components/atoms";
import LinesEllipsis from "react-lines-ellipsis";
// import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
// import { defaultLocale } from "@temp/components/Locale/Locale";
// import { useLocalStorage } from "@hooks";
import PassTypeIcon from "@temp/components/PassTypeIcon";
import * as fbq from "../../lib/fbq.js";
import * as gtag from "../../lib/gtag.js";
import { getDBIdFromGraphqlId } from "@utils/core";
import moment = require("moment");

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const HotelsListView = ({
  data: {
    sellerUsers: { edges, pageInfo },
  },
  locationName,
  date,
  loadingMore,
  onLoadMore,
  countryCode,
  intl,
}) => {
  // const readMoreText = intl.formatMessage({
  //   id: "src.components.HotelsList.readMoreText"
  // });
  const ratingsEnums = { ONE: 1, TWO: 2, THREE: 3, FOUR: 4, FIVE: 5 };

  const handleScroll = ({ currentTarget }, onLoadMore) => {
    onLoadMore();
  };

  /*
  FB Search Event:

  fbq('track', 'Search', {
    // Fire the 'Search' event on the search results page

    // IF YOU CHOOSE NOT TO USE A RECOMMENDED PARAM, THEN REMOVE IT, DON'T LEAVE IT EMPTY

    // REQUIRED: DO NOT change this, must be set to 'hotel'
    content_type: 'hotel',

    // HIGHLY RECOMMENDED: checkin date
    // Allows you to target people based on their travel dates (using a booking window)
    // Improves the landing experience with travel dates filled in (using template tags)
    // use YYYYMMDD, YYYY-MM-DD, YYYY-MM-DDThh:mmTZD or YYYY-MM-DDThh:mm:ssTZD
    checkin_date: '2018-04-01',

    // HIGHLY RECOMMENDED: checkout date
    // use YYYYMMDD, YYYY-MM-DD, YYYY-MM-DDThh:mmTZD or YYYY-MM-DDThh:mm:ssTZD
    checkout_date: '2018-04-05',

    // RECOMMENDED: content ids - include eg top 5 search results
    content_ids: ['123', '234', '345', '456', '567'],

    // REQUIRED: city, don't use abbreviations
    city: 'New York',

    // REQUIRED: region, don't use abbreviations
    region: 'New York',

    // REQUIRED: country, don't use abbreviations
    country: 'United States',

    // RECOMMENDED: number of adults
    num_adults: 1,

    // RECOMMENDED: number of children
    num_children: 0,


  }
   */
  const generateDataToReport = (
    checkinDate,
    contentIds,
    city,
    region,
    country,
    numAdults = 1,
    numChildren = 0
  ) => {
    return {
      content_type: "hotel",
      checkin_date: checkinDate,
      checkout_date: checkinDate,
      content_ids: contentIds,
      city,
      region,
      country,
      num_adults: numAdults,
      num_children: numChildren,
      affiliation: "DailyPass",
    };
  };

  const reportSearchAction = async (searchData) => {
    await fbq.searched(searchData);
  };

  React.useEffect(() => {
    (async () => {
      // Facebook Search  Event reporting
      // const contentIds = edges.map(({ node: { products } }) => {
      //   return products[0].variants[0].facebookId;
      // });
      // const city = locationName;
      // const region = locationName;
      // const country = "Thailand";
      // const numAdults = 1;
      // const numChildren = 0;
      const checkinDate = moment(date).format("YYYY-MM-DD");

      // const viewData = generateDataToReport(
      //   checkinDate,
      //   contentIds,
      //   city,
      //   region,
      //   country,
      //   numAdults,
      //   numChildren
      // );
      // await reportSearchAction(viewData);

      // Google Search Event
      gtag.event("search", {
        search_term: `${locationName} - ${checkinDate}`,
      });

      // Google View ItemList Event
      // const items = edges.map(
      //   (
      //     {
      //       node: {
      //         id: hotelId,
      //         slug,
      //         products,
      //         maxDiscount,
      //         passTypes = [],
      //         sellerInfo: {
      //           hotelName,
      //           starRating,
      //           mainHotelImage,
      //           hotelShortDescription: hotelShortDescriptionEn,
      //           hotelShortDescriptionTh: hotelShortDescriptionTh,
      //           area: { name: hotelArea },
      //         },
      //       },
      //     },
      //     index
      //   ) => {
      //     const product = products[0];
      //     const variantId = product.variants[0].id;
      //     const currency = product.minimalVariantPrice.currency;
      //     const price = product.sellingPriceAmount;
      //     return {
      //       item_id: getDBIdFromGraphqlId(variantId, "ProductVariant"),
      //       item_name: `${hotelName} Voucher`,
      //       affiliation: "DailyPass",
      //       currency,
      //       index,
      //       price,
      //       quantity: 1,
      //     };
      //   }
      // );
      //
      // gtag.event("view_item_list", {
      //   items: [items],
      // });
    })();
  }, [edges, locationName]);

  return (
    <div className="mainHotelListContainer">
      <div className="searchPanelContainer">
        <SearchPanel
          className="search-panel-hotel-list"
          locationName={locationName}
          countryStartingCode={countryCode}
        />
      </div>
      {edges.map(
        ({
          node: {
            id: hotelId,
            slug,
            passTypes = [],
            sellerInfo: {
              hotelName,
              starRating,
              mainHotelImage,
              area: { name: hotelArea },
            },
          },
        }) => {
          const passTypeLinks = passTypes?.map((p) => p?.icon);
          const hotelDetailsUrl = `${paths.hotelDetails}${slug}?date=${date}`;

          return (
            <Link key={hotelId} className="hotelCard" to={hotelDetailsUrl} target="_blank">
              <div className="hotelImageContainer">
                <img
                  className="hotelImage"
                  src={mainHotelImage || ""}
                  alt=""
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.style.display = "none";
                  }}
                />
              </div>
              <div className="hotelInfoContainer">
                <div className="hotelInfoSection">
                  <div className="hotelFirstInfoContainer">
                    <div className="hotelMainInfoContainer">
                      <div className="hotelName">
                        <ResponsiveEllipsis
                          text={hotelName || ""}
                          maxLine="4"
                          ellipsis="..."
                          basedOn="words"
                          trimRight
                        />
                      </div>
                      <div className="hotelMiddleInfoContainer">
                        <div className="hotelSmallInfoContainer">
                          <div className="hotelRatingContainer">
                            <Rating
                              className="ratingStarsStyles"
                              max={ratingsEnums[starRating]}
                              value={ratingsEnums[starRating]}
                              readOnly
                            />
                          </div>

                          <div className="hotelAddressContainer">
                            {hotelArea && (
                              <>
                                <div className="locationIconContainer">
                                  <ReactSVG path={mapIconPath} />
                                </div>
                                <div className="hotelAddress">
                                  <ResponsiveEllipsis
                                    text={hotelArea}
                                    maxLine="2"
                                    ellipsis="..."
                                    basedOn="words"
                                    trimRight
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="iconsContainer">
                          <PassTypeIcon
                            passTypeLinks={passTypeLinks}
                            flexWrap="wrap"
                            justifyContent="flex-end"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="rightInfoContainer">
                      <PassTypeIcon passTypeLinks={passTypeLinks} />
                    </div>
                  </div>

                  <div className="locationIconContainer"></div>
                </div>

                <div className="extraInformationContainer">
                  <div className="viewDetailsButtonContainer regularButtonContainer">
                    <Button className="regularButton" variant="contained">
                      <FormattedMessage
                        id="src.components.HotelsList.viewDetailsButton"
                        defaultMessage="View Details"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </Link>
          );
        }
      )}
      <div className="loadMoreButtonContainer regularButtonContainer">
        {loadingMore ? (
          <Loader />
        ) : (
          pageInfo.hasNextPage && (
            <Button
              className="regularButton"
              variant="contained"
              color="primary"
              onClick={(e) => handleScroll(e, onLoadMore)}
            >
              <FormattedMessage
                id="src.components.HotelsList.LoadMoreButtonText"
                defaultMessage="Load more"
              />
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default injectIntl(HotelsListView);
