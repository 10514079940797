import React from "react";
import "./scss/index.scss";

import Board from "./Board";
import NewToPlatform from "./NewToPlatform";
import SelectPass from "./SelectPass";
import HowItWorks from "./HowItWorks";
import { SEOWrapper } from "@temp/components/SEO";

const HomePage = () => {
  return (
    <SEOWrapper pathname={window.location.pathname}>
      <div className="home-page">
        <Board />
        <NewToPlatform />
        <SelectPass />
        <HowItWorks />
      </div>
    </SEOWrapper>
  );
};

export default HomePage;
