import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React from "react";
import ReactSVG from "react-svg";
import chevronDownPath from "src/images/chevron-down.svg";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import "./scss/index.scss";
// import * as fbq from "../../../lib/fbq.js";
import BookNowButton from "./BookNowButton";
import BookNowButtonBlocked from "@temp/components/HotelDetails/HotelVouchers/BookNowButtonBlocked";
import PassTypeIcon from "src/components/PassTypeIcon";
import minusPath from "src/images/minus.svg";
import plusPath from "src/images/plus.svg";
import { rbrColors } from "@temp/app/ThemeProvider";
import { getCurrencySymbol } from "@temp/utils";
// import moment = require("moment");
const NewLineToBr = ({ text }: { text: string }): React.ReactElement =>
  text ? (
    <>
      <div style={{ padding: "10px 30px" }}>
        {text.split("\n").reduce(
          (arr, line, index) =>
            arr.concat(
              <li key={index}>
                {line}
                <br />
              </li>
            ),
          []
        )}
      </div>
      <Divider variant="middle" />
    </>
  ) : null;

export const HotelVoucherTitleLine = ({ voucherTypeName }) => {
  const classes = useStyles();

  return <div className={classes.voucherTitleLeftBlank}>{voucherTypeName} Pass</div>;
};

const GridThreeLines = ({
  classLeft,
  classMiddle,
  classRight,
  contentLeft,
  contentMiddle,
  contentRight,
  className,
}) => {
  return (
    <Grid container direction="row" className={className}>
      {classLeft != null ? (
        <Grid item xs={4} className={classLeft}>
          {contentLeft}
        </Grid>
      ) : (
        <Grid item xs={4}>
          {contentLeft}
        </Grid>
      )}
      <Grid item className={classMiddle} xs={4}>
        {contentMiddle}
      </Grid>
      <Grid item className={classRight} xs={4}>
        {contentRight}
      </Grid>
    </Grid>
  );
};

const VoucherTopPartOpen = ({ description }) => {
  return (
    <>
      <NewLineToBr text={description} />
    </>
  );
};

const QuantityRegulator = ({
  titleMessage,
  hintMessage,
  classes,
  reduceFunc,
  quantity,
  canAdd,
  addFunc,
  pricePerOne,
  hintClass = classes.detailsHint,
  minQuantity = 0,
  currency,
}) => {
  return (
    <div>
      <GridThreeLines
        className={null}
        classLeft={classes.firstGridCol}
        classMiddle={classes.mediumGridCol}
        classRight={classes.lastGridCol}
        contentLeft={
          <div className={classes.inBl}>
            <div className={classes.detailsItemTitle}>{titleMessage}</div>
            <div className={hintClass}>{hintMessage}</div>
          </div>
        }
        contentMiddle={
          <div className={classes.inBl2}>
            <div className={classes.detailsQuantity}>
              <span
                onClick={reduceFunc}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                <ReactSVG
                  path={minusPath}
                  className={
                    quantity === minQuantity
                      ? classes.counterChevronLeftDisabled
                      : classes.counterChevronLeft
                  }
                />
              </span>
              <div className={canAdd ? classes.detailsValue : classes.detailsValueDisabled}>
                {quantity}
              </div>
              <div className={classes.detailsCounter}>
                <span
                  onClick={addFunc}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  <ReactSVG
                    path={plusPath}
                    className={
                      canAdd ? classes.counterChevronRight : classes.counterChevronRightDisabled
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        }
        contentRight={
          <>
            {quantity ? (
              <div className={classes.priceContainer}>
                <div className={classes.priceRight}>
                  {getCurrencySymbol(currency)}
                  {pricePerOne * quantity}
                </div>
              </div>
            ) : (
              <div className={classes.priceContainer}>
                <div className={classes.priceRight} />
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

const HotelVoucherItemOpen = ({
  acceptingBookings,
  classes,
  currency,
  description,
  hotelName,
  mainHotelImage,
  selectedDate,
  sellingPrice,
  setOpen,
  voucherTypeName,
  locationName,
  seatsLeft,
  childPrice,
  allowChildren,
  sellerId,
  venuePassId,
  reservationEmail,
}) => {
  const maxAll = seatsLeft;
  const [adultQuantity, setAdultQuantity] = React.useState(1); // 1 is for the default 1 adult who buys pass
  const [childQuantity, setChildQuantity] = React.useState(0);

  const canAdd = adultQuantity + childQuantity < maxAll;
  const canAddAdult = canAdd;
  const canAddChild = allowChildren && canAdd;
  const adultTotal = sellingPrice * adultQuantity;
  const childTotal = childPrice * childQuantity;
  const total = adultTotal + childTotal;
  const addAdult = () => {
    if (canAddAdult) {
      setAdultQuantity((prev) => prev + 1);
    }
  };

  const reduceAdult = () => {
    if (adultQuantity < 2) return;
    setAdultQuantity((prev) => prev - 1);
  };

  const addChild = () => {
    if (canAddChild) {
      setChildQuantity((prev) => prev + 1);
    }
  };

  const reduceChild = () => {
    if (childQuantity < 1) return;
    setChildQuantity((prev) => prev - 1);
  };
  const voucherTitle = `${voucherTypeName} Pass`;
  return (
    <div className={classes.voucherMetaContainerOpen}>
      <div className={classes.voucherContainer}>
        <div
          className={classes.voucherTitle}
          onClick={() => {
            setOpen(false);
          }}
        >
          <HotelVoucherTitleLine voucherTypeName={voucherTypeName} />
        </div>
      </div>

      <div className={classes.orderDetails}>
        <div className={classes.detailsContainer}>
          <VoucherTopPartOpen
            classes={classes}
            currency={getCurrencySymbol(currency)}
            description={description}
            sellingPrice={sellingPrice}
          />
          <div className={classes.startingReducers} />
          <QuantityRegulator
            titleMessage={<FormattedMessage id="text.adult" />}
            hintMessage={<FormattedMessage id="text.overage" />}
            classes={classes}
            reduceFunc={reduceAdult}
            quantity={adultQuantity}
            canAdd={canAddAdult}
            addFunc={addAdult}
            pricePerOne={sellingPrice}
            minQuantity={1}
            currency={currency}
          />
          <QuantityRegulator
            titleMessage={<FormattedMessage id="text.kids" />}
            hintMessage={
              allowChildren ? (
                <FormattedMessage id="text.kidage" />
              ) : (
                <FormattedMessage id="text.notallowed" />
              )
            }
            hintClass={allowChildren ? classes.detailsHint : classes.redHint}
            classes={classes}
            reduceFunc={reduceChild}
            quantity={childQuantity}
            canAdd={canAddChild}
            addFunc={addChild}
            pricePerOne={childPrice}
            currency={currency}
          />
        </div>
        <div className={classes.totalPrice}>
          <span className={classes.totalPriceTotal}>
            <strong>
              <FormattedMessage id="text.totaltax1" />
            </strong>
          </span>
          <span className={classes.totalPriceValue}>
            {getCurrencySymbol(currency)}
            {total}
          </span>
        </div>
        {acceptingBookings ? (
          <BookNowButton
            currency={currency}
            adultTotal={adultTotal}
            childTotal={childTotal}
            adultQuantity={adultQuantity}
            childQuantity={childQuantity}
            hotelName={hotelName}
            mainHotelImage={mainHotelImage}
            passTypeName={voucherTypeName}
            selectedDate={selectedDate}
            locationName={locationName}
            total={total}
            voucherTitle={voucherTitle}
            pricePerAdult={sellingPrice}
            pricePerChild={childPrice}
            sellerId={sellerId}
            venuePassId={venuePassId}
          />
        ) : (
          <BookNowButtonBlocked
            available={false}
            passType={voucherTypeName}
            reservationEmail={reservationEmail}
          />
        )}
      </div>
    </div>
  );
};

const HotelVoucherItemClosed = ({
  bestSeller,
  classes,
  open,
  sellingPrice,
  setOpen,
  voucherTypeName,
  passTypeIconLink,
  currency,
}) => {
  return (
    <div>
      {bestSeller && (
        <Grid container direction="row">
          <Grid item xs={1} />
          <Grid item xs={4} className={classes.bestSellerBadge}>
            <FormattedMessage id="text.bestseller" />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={6} />
        </Grid>
      )}

      <div
        className={
          bestSeller ? classes.voucherMetaContainerBestseller : classes.voucherMetaContainer
        }
      >
        <div
          className={bestSeller ? classes.voucherContainerBestSeller : classes.voucherContainer}
          onClick={() => {
            if (!open) {
              setOpen(true);
            }
          }}
        >
          <div
            className={classes.voucherTitle}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <HotelVoucherTitleLine voucherTypeName={voucherTypeName} />
            <div />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              columnGap: "5px",
              marginTop: "10px",
              fontFamily: "'ProximaNova-Bold', sans-serif",
              color: "#3D3D3D",
            }}
          >
            {"Show details"}
            <ReactSVG path={chevronDownPath} className={classes.chevron} />
          </div>
          <div
            className={
              bestSeller ? classes.basePlateContainerBestSeller : classes.basePlateContainer
            }
          >
            <PassTypeIcon passTypeLinks={[passTypeIconLink]} />

            <div className={classes.priceContainer}>
              <div>
                <div className={classes.price}>
                  {getCurrencySymbol(currency)}
                  {sellingPrice}
                </div>
                <div className={classes.voucherPriceMiddleSecondLine}></div>
              </div>
            </div>

            <div className={classes.basePlateSelect}>
              <Button
                classes={{
                  root: bestSeller ? classes.selectButtonBestSeller : classes.selectButton,
                  label: bestSeller
                    ? classes.selectButtonLabelBestSeller
                    : classes.selectButtonLabel,
                }}
              >
                <ReactSVG path={chevronDownPath} className={classes.chevron} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const HotelVoucherItem = ({
  acceptingBookings,
  bestSeller,
  currency,
  description,
  hotelName,
  mainHotelImage,
  childPrice,
  allowChildren,
  selectedDate,
  sellingPrice,
  voucherTypeName,
  locationName,
  seatsLeft,
  passTypeIconLink,
  sellerId,
  venuePassId,
  reservationEmail,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const setOpenFunc = (setOpen, newState) => {
    setOpen(newState);

    // (async () => {
    //   const checkinDate = moment(selectedDate).format("YYYY-MM-DD");
    //   await fbq.viewContent({
    //     value: sellingPrice,
    //     currency: currency,
    //     contents: [
    //       {
    //         id: venuePassId,
    //         name: `${hotelName} ${currency} Voucher`,
    //         price: sellingPrice,
    //         quantity: 1,
    //       },
    //     ],
    //     content_type: "hotel",
    //     content_ids: [venuePassId],
    //     checkin_date: checkinDate,
    //     checkout_date: checkinDate,
    //     num_adults: adultQuantity,
    //     num_children: childQuantity,
    //   });
    // })();
  };

  return (
    <div className={classes.marginLeft30}>
      {open ? (
        <HotelVoucherItemOpen
          acceptingBookings={acceptingBookings}
          classes={classes}
          currency={currency}
          description={description}
          allowChildren={allowChildren}
          childPrice={childPrice}
          hotelName={hotelName}
          mainHotelImage={mainHotelImage}
          selectedDate={selectedDate}
          sellingPrice={sellingPrice}
          setOpen={(newState) => setOpenFunc(setOpen, newState)}
          voucherTypeName={voucherTypeName}
          locationName={locationName}
          seatsLeft={seatsLeft}
          sellerId={sellerId}
          venuePassId={venuePassId}
          reservationEmail={reservationEmail}
        />
      ) : (
        <HotelVoucherItemClosed
          bestSeller={bestSeller}
          classes={classes}
          open={open}
          sellingPrice={sellingPrice}
          setOpen={(newState) => setOpenFunc(setOpen, newState)}
          voucherTypeName={voucherTypeName}
          passTypeIconLink={passTypeIconLink}
          currency={currency}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  marginLeft30: {
    [theme.breakpoints.between("md", "lg")]: {
      marginLeft: "30px",
    },
  },
  total: {
    "&::first-letter": {
      fontWeight: "bold",
    },
  },
  bestSellerBadge: {
    backgroundColor: rbrColors.darkOrange,
    color: "#ffffff",
    marginLeft: "0",
    textAlign: "center",
    fontSize: "0.875rem",
    lineHeight: "1.0625rem",
    paddingBottom: "2.5rem",
    paddingTop: "0.5rem",
    marginBottom: "-2rem",
    zIndex: -1,
    borderRadius: "0.5625rem",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "1.4rem",
    },
    [theme.breakpoints.between("md", "lg")]: {
      marginLeft: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "0",
    },
  },
  discountBadge: {
    width: "4rem",
    height: "4rem",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 2px #ffffff",
    backgroundColor: rbrColors.darkOrange,
    borderRadius: "2rem",
  },
  discountBadgeText: {
    fontSize: "1.1rem",
    color: "white",
    paddingTop: "0.5rem",
    paddingLeft: "1px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    fontFamily: "Roboto",
    lineHeight: "0.87",
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  adultContainer: {
    marginTop: "1rem",
    marginLeft: "1rem",
    marginBottom: "1.5rem",
    fontSize: "1.2rem",
  },
  firstGridCol: {
    marginLeft: "1rem",
    marginRight: "-1rem",
  },
  mediumGridCol: {
    marginLeft: "1rem",
    marginRight: "-1rem",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "1rem",
    },
    [theme.breakpoints.up(410)]: {
      paddingLeft: "1.5rem",
      marginLeft: "0",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "3rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "2.3rem",
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "4.6rem",
    },
  },
  lastGridCol: {},
  notice: {
    fontSize: "9px",
    paddingLeft: "1rem",
    lineHeight: "7px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "11px",
    },
  },
  header: {
    fontSize: "14px",
    paddingBottom: "1rem",
    lineHeight: "12px",
    fontWeight: 600,
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
      lineHeight: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  crossedOut: {
    textDecoration: "line-through",
  },
  totalPriceTotal: {
    fontSize: "1.15rem",
    marginRight: "-12.3rem",
    [theme.breakpoints.down("md")]: {
      marginRight: "-9.6rem",
    },
    [theme.breakpoints.up("xs")]: {
      marginRight: "-3.6rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginRight: "-7.6rem",
    },
  },
  totalPriceTax: {
    paddingTop: "1px",
  },
  displayContentContainer: {
    textAlign: "left",
    display: "flex",
    width: "128px",
  },
  adultLineMiddle: {
    textAlign: "center",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "0",
    },
    [theme.breakpoints.up(375)]: {
      paddingRight: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: "0",
    },
  },
  adultLineLast: {
    textAlign: "right",
    paddingRight: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      paddingRight: "1.5rem",
    },
    [theme.breakpoints.up(375)]: {
      paddingRight: "2rem",
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: "2rem",
    },
  },
  adultsStyle: {
    fontSize: "20px",
    marginLeft: "2.6em",
    color: "#000",
    marginRight: "0.5em",
  },
  priceTopRight: {
    textAlign: "center",
    padding: "1rem 0",
    fontSize: "1.2rem",
  },
  priceRight: {
    fontSize: "1.2rem",
  },
  startingReducers: {
    marginTop: "0.5rem",
  },
  inBl: {
    display: "inline-block",
    width: "8rem",
    [theme.breakpoints.up("sm")]: {
      verticalAlign: "sub",
    },
    [theme.breakpoints.up("xs")]: {
      verticalAlign: "sub",
      width: "5rem",
    },
  },
  inBl2: {
    display: "inline-block",
    [theme.breakpoints.up("xs")]: {
      height: "25px",
      marginBottom: "0.8rem",
    },
  },
  priceGreyedOut: {
    color: "#707070",
    padding: "0.9rem 0 0.8rem",
    position: "relative",
    fontSize: "1.2rem",
    textAlign: "center",
  },
  voucherTitleGreyedOut: {
    backgroundColor: "#707070",
    color: "#FFFFFF",
    borderBottom: "none",
    fontWeight: 500,
    fontSize: "1rem",
    padding: "0.5rem 0.5rem",
    letterSpacing: "0.1em",
    borderRadius: "50px",
    height: "3rem",
    [theme.breakpoints.up("xs")]: {
      padding: "0.7rem 0rem 0rem 0.3rem",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0.8rem 0rem 0rem 0.5rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "1.1rem 0rem 0rem 0.5rem",
      height: "3.5rem",
    },
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(97.5%)",
      "& > *:hover": {
        filter: "brightness(97.5%)",
      },
      "& > div:hover": {
        filter: "brightness(97.5%)",
      },
    },
  },
  color: {
    color: rbrColors.mainColor,
    display: "contents",
  },
  alertMessage: {
    margin: "1rem 1rem 1rem 1rem",
  },
  voucherTitleLeft: {
    textAlign: "center",
    display: "inline-block",
    width: "88%",
    marginLeft: "1.5rem",
    marginTop: "0.2rem",
    fontWeight: 600,
    fontSize: "15px",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginLeft: "0rem",
      fontSize: "17px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "19px",
      width: "100%",
      marginLeft: "0rem",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "15px",
      width: "100%",
      marginLeft: "0rem",
    },
  },
  voucherTitleLeftBlank: {
    textAlign: "center",
    display: "inline-block",
    width: "88%",
    marginLeft: "1.5rem",
    marginTop: "0.2rem",
    fontWeight: 600,
    fontSize: "15px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "13px",
      width: "100%",
      marginLeft: "0rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "19px",
      width: "100%",
      marginLeft: "0rem",
      marginTop: "0",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      marginLeft: "0rem",
      fontSize: "17px",
      marginTop: "0",
    },
  },
  SaveNowText: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
      lineHeight: "22px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "19px",
      lineHeight: "28px",
    },
  },
  voucherTitleRight: {
    textAlign: "right",
    display: "inline-block",
    color: "#000000",
    [theme.breakpoints.up("xs")]: {
      fontSize: "27x",
      lineHeight: "22px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "23px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
  },
  voucherPriceMiddleSecondLine: {
    textAlign: "right",
    color: "#000000",
    fontSize: "13px",
  },
  voucherContainer: {
    //marginBottom: "1rem",
    borderRadius: "1.4375rem",
  },
  voucherContainerBestSeller: {
    marginBottom: "2rem",
    backgroundColor: "#fff4e0",
    borderRadius: "1.7375rem",
  },
  voucherMetaContainer: {
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    WebkitBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    MozBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    width: "90%",
    borderRadius: "1.7375rem",
    marginBottom: "2rem",

    [theme.breakpoints.down("md")]: {
      margin: "auto auto 2rem auto",
    },
    [theme.breakpoints.between("md", "lg")]: {
      maxWidth: "402.75px",
    },

    "&:hover": {
      filter: "brightness(97.5%)",
      "& > *:hover": {
        filter: "brightness(97.5%)",
      },
      "& > div:hover": {
        filter: "brightness(97.5%)",
      },
    },
  },
  voucherMetaContainerBestseller: {
    cursor: "pointer",
    backgroundColor: "#ffe8e9",
    WebkitBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    MozBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    width: "90%",
    borderRadius: "1.7375rem",
    marginBottom: "2rem",

    [theme.breakpoints.down("md")]: {
      margin: "auto auto 2rem auto",
    },
    [theme.breakpoints.between("md", "lg")]: {
      maxWidth: "402.75px",
    },
    "&:hover": {
      filter: "brightness(97.5%)",
      "& > *:hover": {
        filter: "brightness(97.5%)",
      },
      "& > div:hover": {
        filter: "brightness(97.5%)",
      },
    },
  },
  voucherMetaContainerOpen: {
    backgroundColor: "#FFFFFF",
    WebkitBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    MozBoxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16);",
    width: "90%",
    borderRadius: "1.9375rem",
    marginBottom: "2rem",

    [theme.breakpoints.down("md")]: {
      margin: "auto auto 2rem auto",
    },
    [theme.breakpoints.between("md", "lg")]: {
      maxWidth: "402.75px",
    },
  },
  voucherTitle: {
    border: `1px solid ${rbrColors.mainColor}`,
    borderBottom: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontWeight: 500,
    fontSize: "1rem",
    padding: "0.5rem 0.5rem",
    letterSpacing: "0.1em",
    borderRadius: "50px",
    height: "3rem",
    [theme.breakpoints.up("xs")]: {
      padding: "0.7rem 0rem 0rem 0.3rem",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0.8rem 0rem 0rem 0.5rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "1.1rem 0rem 0rem 0.5rem",
      height: "3.5rem",
      fontSize: "17px",
    },
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(97.5%)",
      "& > *:hover": {
        filter: "brightness(97.5%)",
      },
      "& > div:hover": {
        filter: "brightness(97.5%)",
      },
    },
  },
  voucherValueLabel: {
    color: theme.palette.background.paper,
    fontWeight: 600,
  },
  basePlateContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-between",
    padding: "0 1em",
    height: "6.7rem",
    borderRadius: "1.4375rem",
  },
  basePlateContainerBestSeller: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 1em",
    height: "6.7rem",
    backgroundColor: rbrColors.accentBackground,
    borderRadius: "1.4375rem",
  },
  basePlatePrice: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  priceContainer: {
    textAlign: "right",
  },
  priceContainerRight: {
    display: "flex",
    flexDirection: "column",
  },
  priceContainer2: {
    display: "flex",
    lineHeight: "1.25em",
    textAlign: "center",
    color: "black",
    fontWeight: 600,
  },
  priceContainer2Blank: {
    display: "flex",
    padding: "0",
    lineHeight: "1.25em",
    textAlign: "center",
    color: "darkgray",
    fontWeight: 600,
    marginRight: "-1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "13px",
      paddingLeft: "3rem",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "15px",
    },
  },
  priceLabel: {
    marginBottom: "1px",
    fontWeight: 500,
    fontSize: "1.0rem",
    padding: "0.5rem 0.5rem 0.5rem 0.25rem",
    textAlign: "center",
  },
  priceLabel2: {
    marginBottom: "1px",
    fontWeight: 500,
    fontSize: "1.0rem",
    padding: "0.5rem 0.5rem 0.5rem 0.25rem",
    textAlign: "center",
    color: rbrColors.mainColor,
  },
  price: {
    textAlign: "center",
    padding: "0.9rem 0 0.8rem",
    position: "relative",
    fontSize: "1.55rem",
    color: rbrColors.accentColor,
    fontWeight: 600,
    [theme.breakpoints.up("xs")]: {
      fontSize: "30px",
      lineHeight: "25px",
      // marginLeft: "3rem"
    },
    [theme.breakpoints.up(375)]: {
      fontSize: "30px",
      lineHeight: "25px",
      // marginLeft: "5rem"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "40px",
      lineHeight: "30px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "34px",
      lineHeight: "30px",
    },
  },
  priceBlank: {
    textAlign: "center",
    padding: "0.9rem 0 0.8rem",
    position: "relative",
    fontSize: "1.55rem",
    color: "#827e7e",
    fontWeight: 600,
    [theme.breakpoints.up("xs")]: {
      fontSize: "30px",
      lineHeight: "25px",
      // marginLeft: "3rem"
    },
    [theme.breakpoints.up(375)]: {
      fontSize: "30px",
      lineHeight: "25px",
      // marginLeft: "5rem"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "30px",
    },
  },
  priceBottomLabel: {
    position: "absolute",
    bottom: 0,
    borderTop: `2px solid #dedede`,
    fontSize: "0.65rem",
    textAlign: "center",
    width: "100%",
    lineHeight: "1.5",
  },
  basePlateSelect: {
    display: "flex",
    justifyContent: "center",
  },
  buttonLabel: {
    color: "#ffffff !important",
    width: "76%",
  },
  selectButton: {
    width: "90%",
    color: rbrColors.mainColor,
    backgroundColor: "#ffffff",
    padding: "0px",
    minWidth: "24px",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  selectButtonBestSeller: {
    width: "90%",
    color: rbrColors.mainColor,
    backgroundColor: rbrColors.accentBackground,
    padding: "0px",
    minWidth: "24px",
    "&:hover": {
      backgroundColor: rbrColors.accentBackground,
    },
  },
  selectButtonLabel: {
    width: "90%",
    color: "#ffffff",
    backgroundColor: "#ffffff",
  },
  selectButtonLabelBestSeller: {
    width: "90%",
    color: "#ffffff",
    backgroundColor: rbrColors.accentBackground,
  },
  unavailableButton: {
    width: "90%",
    color: "#ffffff",
    backgroundColor: rbrColors.mainColor,
    fontSize: "12px",
    marginRight: "5%",
  },
  chevron: {
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& svg": {
      height: "1rem",
      width: "auto",

      "& path": {
        fill: "#000000",
      },
    },
  },

  orderDetails: {
    borderTop: "none",
  },
  totalPrice: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 1em",
    color: theme.palette.text.primary,
    fontWeight: 400,
    marginTop: "1rem",
    marginBottom: "0.6rem",
    backgroundColor: "#f8f7f7",
  },
  detailsContainer: {
    justifyContent: "space-around",
  },
  detailsItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 1em 0",
  },
  detailsItemTitle: {
    color: "#000000",
    fontWeight: 400,
    fontSize: "18px",
    marginTop: "0.25rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.up("xs")]: {
      fontSize: "15px",
    },
  },
  detailsHint: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "12px",
    marginLeft: "0",
    marginTop: "-0.4rem",
    [theme.breakpoints.up("xs")]: {
      fontSize: "11px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
  },
  redHint: {
    color: "red",
    fontWeight: 400,
    fontSize: "10px",
    marginLeft: "0",
    marginTop: "-0.4rem",
    [theme.breakpoints.up("xs")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
    },
  },
  detailsHintServiceCharge: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "11px",
    marginLeft: "0",
    marginTop: "0.2rem",
  },
  detailsHintReduction: {
    color: "red",
    fontWeight: 600,
    fontSize: "1.0rem",
    marginTop: "-0.25vw",
  },
  totalPriceValue: {
    fontWeight: 600,
    color: rbrColors.accentColor,
    fontSize: "19px",
    marginRight: "-0.4rem",

    [theme.breakpoints.down("md")]: {
      fontSize: "19px",
      marginRight: "0rem",
    },
  },
  detailsQuantity: {
    display: "flex",
    alignItems: "center",
    border: `2px solid #dedede`,
    margin: "0.5rem 0",
    width: "7rem",
    justifyContent: "space-evenly",
    [theme.breakpoints.up("sm")]: {
      width: "6.7rem",
      margin: "0 0 0.5rem -1rem",
      borderRadius: "5px",
      border: "solid 1px #707070",
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.up(375)]: {
      width: "6.7rem",
      margin: "0 0 0.5rem 0",
      borderRadius: "5px",
      border: "solid 1px #707070",
      backgroundColor: "#ffffff",
    },
    [theme.breakpoints.up("xs")]: {
      width: "5.4rem",
      margin: "0 0 0.5rem 0",
      borderRadius: "5px",
      border: "solid 1px #707070",
      backgroundColor: "#ffffff",
    },
  },
  detailsCounter: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginLeft: "-18px",
    marginTop: "-5px",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "-1.4rem",
    },
  },
  detailsValue: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "1.25rem",
    [theme.breakpoints.up(375)]: {
      padding: "0.375rem 2.5rem 0.2rem 1.25rem",
    },
    [theme.breakpoints.up("xs")]: {
      padding: "0.375rem 2.0rem 0.2rem 0.75rem",
    },
  },
  detailsValueDisabled: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "1.25rem",
    [theme.breakpoints.up(375)]: {
      padding: "0.375rem 2.5rem 0.2rem 1.25rem",
    },
    [theme.breakpoints.up("xs")]: {
      padding: "0.375rem 2.0rem 0.2rem 0.75rem",
    },
  },
  counterChevronLeft: {
    [theme.breakpoints.up("xs")]: {
      width: "1.6rem",
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeftWidth: "2px",
      cursor: "pointer",
      padding: "0 0.5rem",
    },

    "& svg": {
      height: "1.25rem",
      width: "auto",

      "& path": {
        fill: theme.customColors.brand,
      },
    },
  },
  counterChevronLeftDisabled: {
    [theme.breakpoints.up("xs")]: {
      width: "1.6rem",
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeftWidth: "2px",
      cursor: "pointer",
      padding: "0 0.5rem",
    },

    "& svg": {
      height: "1.25rem",
      width: "auto",

      "& path": {
        fill: "#c9c6c6",
      },
    },
  },
  counterChevronRight: {
    [theme.breakpoints.up("xs")]: {
      width: "1.6rem",
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeftWidth: "2px",
      cursor: "pointer",
      padding: "0.3rem 0.5rem 0rem 0.5rem",
    },

    "& svg": {
      height: "1.125rem",
      width: "auto",

      "& path": {
        fill: theme.customColors.brand,
      },
    },
  },
  counterChevronRightDisabled: {
    [theme.breakpoints.up("xs")]: {
      width: "1.6rem",
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeftWidth: "2px",
      cursor: "pointer",
      padding: "0.3rem 0.5rem 0rem 0.5rem",
    },

    "& svg": {
      height: "1.125rem",
      width: "auto",

      "& path": {
        fill: "#c9c6c6",
      },
    },
  },
  blankItemCcontainer: {
    marginBottom: "2rem",
  },
  voucherDisabled: {
    backgroundColor: "#d7dbd9",
    color: "#fff",
    textAlign: "center",
    padding: "2rem",
  },
}));

export default HotelVoucherItem;
