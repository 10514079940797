import * as React from "react";
import { Route, Switch } from "react-router-dom";

import HomePage from "src/components/Home";
import Onboarding from "src/components/Onboarding";
import { NotFound } from "../../components";
import { AccountConfirm } from "../../views/Account";
import OrderFailed from "../../views/OrderFailed/View";
import OrderConfirmed from "../../views/OrderConfirmed/View";
import { Privacy } from "../../views/Privacy";
import SignUpForm from "src/components/SignUpForm";
// import LeaveReviewForm from "src/components/LeaveReview";
import HotelDetails from "src/components/HotelDetails";
import HotelsList from "src/components/HotelsList";
import { TermsAndConditions } from "../../views/TermsAndConditions";
import { FAQ } from "../../views/FAQ";
import { Covid } from "../../views/Covid";
import { PasswordReset } from "@pages";

import * as paths from "./paths";
import Terms from "@temp/components/Terms";

export class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={"/daily-qr-pass"} component={Onboarding} />
        <Route exact path={paths.termsUrl} component={Terms} />
        <Route exact path={paths.baseUrl} component={HomePage} />
        <Route path={paths.signUpFormUrl} component={SignUpForm} />
        {/*<Route path={`${paths.leaveReviewFormUrl}:sellerId`} component={LeaveReviewForm} />*/}
        <Route path={`${paths.hotelDetails}:hotelSlug`} component={HotelDetails} />
        <Route path={`${paths.hotelsList}`} component={HotelsList} />
        <Route path={paths.privacy} component={Privacy} />
        <Route path={paths.termsandconditions} component={TermsAndConditions} />
        <Route path={paths.faq} component={FAQ} />
        <Route path={paths.covid} component={Covid} />
        <Route path={paths.orderConfirmedUrl} component={OrderConfirmed} />
        <Route path={paths.orderFailedUrl} component={OrderFailed} />
        <Route path={paths.accountConfirmUrl} component={AccountConfirm} />
        <Route path={paths.passwordResetUrl} component={PasswordReset} />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}

export default Routes;
