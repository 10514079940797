import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import HotelVouchersDatePicker from "./HotelVouchersDatePicker";
import { FormattedMessage } from "react-intl";

import calendarIcon from "images/calendar-alt-regular.svg";
import ReactSVG from "react-svg";
import HotelVoucherItem from "@temp/components/HotelDetails/HotelVouchers/HotelVoucherItem";

const HotelVouchers = ({
  venuePasses,
  selectedDate,
  setSelectedDate,
  hotelSlug,
  acceptingBookings,
  locationName,
  hotelName,
  mainHotelImage,
  sellerId,
  reservationEmail,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.vouchersContainer}>
      <div className={classes.bookNowContainer}>
        <div className={classes.selectDate}>
          <FormattedMessage id="text.selectdate" />
        </div>
        <div className={classes.bookNowPicker}>
          <HotelVouchersDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            hotelSlug={hotelSlug}
            hotelLocation={locationName}
          />
          <ReactSVG path={calendarIcon} className={classes.calendarIcon} />
        </div>
      </div>

      <div>
        <div>
          {venuePasses?.map((venuePass) => {
            return (
              venuePass.isForSale && (
                <div key={venuePass.id}>
                  <HotelVoucherItem
                    acceptingBookings={acceptingBookings}
                    bestSeller={venuePass.isBestSeller}
                    currency={venuePass.currency}
                    description={venuePass.description}
                    childPrice={venuePass.childPrice}
                    hotelName={hotelName}
                    mainHotelImage={mainHotelImage}
                    allowChildren={venuePass.allowChildren}
                    selectedDate={selectedDate}
                    sellingPrice={venuePass.sellingPrice}
                    voucherTypeName={venuePass.passTypeName}
                    locationName={locationName}
                    seatsLeft={venuePass.seatsLeft}
                    passTypeIconLink={venuePass?.passTypeIconLink}
                    sellerId={sellerId}
                    venuePassId={venuePass.id}
                    reservationEmail={reservationEmail}
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  vouchersContainer: {
    margin: "0 12%",

    [theme.breakpoints.down("md")]: {
      margin: "0 1rem",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "2rem 15%",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "2rem 0",
    },
  },
  bookNowContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-2rem",
    },
  },
  selectDate: {
    marginRight: "0.5rem",
    color: theme.palette.text.primary,
    fontFamily: "ProximaNova-ExtraBold",
    fontWeight: 500,
    fontSize: "1rem",
    [theme.breakpoints.between(0, 321)]: {
      marginLeft: "12vw",
    },
    [theme.breakpoints.between(321, 375)]: {
      marginLeft: "4vw",
    },
    [theme.breakpoints.between(375, 414)]: {
      marginLeft: "2vw",
    },
    [theme.breakpoints.between(414, 600)]: {
      marginLeft: "-2vw",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "1vw",
    },
  },
  bookNowPicker: {
    position: "relative",

    "& input": {
      textAlign: "center",
      paddingRight: "20px",
    },
  },
  calendarIcon: {
    position: "absolute",
    top: "10%",
    right: "10%",

    "& svg": {
      height: "1.35em",
      marginLeft: "1em",

      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

export default HotelVouchers;
