import React from "react";
import "../scss/index.scss";

import moment from "moment-timezone";
import dates from "./dates";

import ReactSVG from "react-svg";
import chevronDownPath from "src/images/chevron-down.svg";
import { useIntl } from "react-intl";
import { makeDateValidated, storeDate, timezone } from "@temp/utils";

const CalendarGrid = ({
  month,
  monthIndex,
  setMonth,
  setDate,
  timeForStorageInputs,
  setIsCalendarVisible,
}) => {
  const intl = useIntl();
  const firstDate = month[0].find((item) => item.day);
  const title = firstDate.date.clone().locale(intl.locale).format("MMMM YYYY");

  const calendarGridRef = React.useRef(null);
  const [cellWidth, setCellWidth] = React.useState(0);

  const [currentDate, setCurrentDate] = React.useState(null);
  React.useEffect(() => {
    // check if the local storage value for date is fresh

    const storagePickDate = localStorage.getItem("pickDate");
    const storagePickDateMoment = localStorage.getItem("pickDateMoment");

    if (storagePickDate) {
      // if the difference is more one day than stale
      const isStale = storagePickDateMoment
        ? Number(new Date()) - Number(storagePickDateMoment) >
          timeForStorageInputs
        : false;

      if (!isStale) {
        let storageCurrentDate;
        // if is not stale than localStorage value is used
        // but check if this date is exist in calendar and is active
        // and if exists - assign value for the variable (storageCurrentDate)
        const isDateInCalendar = dates.find((month) => {
          return month.find((row) => {
            return row.find((item) => {
              if (
                item.date &&
                item.isActive &&
                item.date.format("D MMMM Y") === storagePickDate
              ) {
                storageCurrentDate = item;
              }
              return (
                item.date &&
                item.isActive &&
                item.date.format("D MMMM Y") === storagePickDate
              );
            });
          });
        });

        if (isDateInCalendar) {
          setCurrentDate(storageCurrentDate);
          return;
        }
      }
    }

    // if there is no value from localStorage then assign default date
    let defaultDate = moment.tz(timezone);

    const currentRow = month.find((row) =>
      row.find(
        (item) =>
          item.date?.format("D MMMM Y") === defaultDate.format("D MMMM Y")
      )
    );

    defaultDate = currentRow.find(
      (item) => item.date?.format("D MMMM Y") === defaultDate.format("D MMMM Y")
    );

    setCurrentDate(defaultDate);
  }, []);

  React.useLayoutEffect(() => {
    function updateWidth() {
      const width = (
        calendarGridRef.current.getBoundingClientRect().width / 7
      ).toFixed(2);
      setCellWidth(Number(width));
    }

    window.addEventListener("resize", updateWidth);

    updateWidth();

    return () => window.removeEventListener("resize", updateWidth);
  });

  const localMoment = moment.tz(timezone).locale(intl.locale);

  return (
    <div className="grid-container">
      <div className="title-container">
        {monthIndex > 0 ? (
          <ReactSVG
            onClick={() => {
              setMonth(monthIndex - 1);
            }}
            path={chevronDownPath}
            style={{ transform: "rotate(90deg)" }}
          />
        ) : (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        )}
        <span className="title">{title}</span>
        {monthIndex < 11 ? (
          <ReactSVG
            onClick={() => {
              setMonth(monthIndex + 1);
            }}
            path={chevronDownPath}
            style={{ transform: "rotate(270deg)" }}
          />
        ) : (
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        )}
      </div>
      <div className="grid" ref={calendarGridRef}>
        <div className="months">
          {localMoment
            .localeData()
            .weekdaysMin()
            .map((day) => (
              <span
                style={{ width: `${cellWidth}px`, height: `${cellWidth}px` }}
              >
                {day}
              </span>
            ))}
        </div>

        {month.map((row, index) => {
          return (
            <div className="dates-row" key={index}>
              {row.map((cell, index) => (
                <span
                  style={{
                    height: `${cellWidth}px`,
                    width: `${cellWidth}px`,
                  }}
                  key={index}
                  className={
                    cell.date && cell.isActive && cell !== currentDate
                      ? "active-cell"
                      : cell.date && cell.isActive && cell === currentDate
                      ? "default-cell"
                      : cell.date && !cell.isActive
                      ? "disable-cell"
                      : "no-date-cell"
                  }
                  onClick={() => {
                    if (cell.date && cell.isActive) {
                      const pickDate = cell.date.format("D MMMM Y");
                      storeDate(makeDateValidated(pickDate), true)
                      setCurrentDate(cell);
                      setDate(cell.date.format("D MMMM Y"));
                      setIsCalendarVisible(false);
                    }
                  }}
                >
                  {cell.date ? cell.date.format("D") : null}
                </span>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CalendarGrid;
