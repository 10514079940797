import React from "react";
import { FormattedMessage } from "react-intl";

import "./scss/index.scss";
import SearchPanel from "@temp/components/SearchPanel";
import posterSquareSrc from "src/images/posterSquare.jpg";
import posterSrc from "src/images/poster.jpg";

const Board = () => {
  return (
    <div className="board">
      <div className="image-container">
        <img className="image-desktop" src={posterSrc} />
        <img className="image-mobile" src={posterSquareSrc} />
        <div className="search-container-hat" />
      </div>
      <div className="poster-text">
        <div className="text-line">
          <FormattedMessage id="homepage.poster1" />
        </div>
        <div className="sub-text-line">
          <FormattedMessage id="homepage.poster2" />
        </div>
      </div>
      <SearchPanel className="search-panel" locationName={"Bangkok"} />
    </div>
  );
};

export default Board;
